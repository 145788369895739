import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import location from '../../assets/images/Icons/room.png';
import viewCarousel from '../../assets/images/Icons/view-carousel.png';
import business from '../../assets/images/Icons/business.png';
import cardLogo from '../../assets/images/card-logo.png';
import { StyledCard } from '../../styles/components/lisitingCard';

const GroupListingCard = ({ group }) => {
	const navigate = useNavigate();

	const ViewStationDetail = (e) => {
		e.preventDefault();
		navigate(`/editGroup/${group.id}`);
	};

	return (
		<StyledCard className='card-dark' onClick={ViewStationDetail}>
			<div className="card-main main-group-card">
				<div className="card-logo">
					{group && group.groupImage ? <img src={group.groupImage} alt="" /> : <img src={cardLogo} alt="" />}
				</div>
				<h3 className="card-title">{group && group.groupName && group.groupName}</h3>
				<p className="card-des">{group && group.groupDescription && group.groupDescription}</p>
				<div className="card-info">
					<div className="card-info-item location-item">
						{group && group.groupLocation && <><img src={location} alt="" /> <p title={group.groupLocation}>{group.groupLocation}</p></>}
					</div>
					{group.stations && group.stations.length && <div className="card-info-item">
						<img src={viewCarousel} alt="" /> {group.stations.length}
					</div>}
					<div className="card-info-item">
						{group && group.stationTimeZone && <><img src={business} alt="" /> {group.stationTimeZone}</>}
					</div>
				</div>
				<Button className="card-button">View group</Button>
			</div>
		</StyledCard>
	);
};

export default GroupListingCard;