import { styled } from '@mui/material/styles';
import { disabledColor, lightBackgorund, mediumEmphasis, white, customBoxShadowColor, highEmphasis, UserHeading, futuraLight } from '../variables';

export const SideMenu = styled('div')(
	() => `
		max-width: 247px;	
		padding: 0px;
		height: 100vh;
		border-right: 2px solid ${disabledColor};
		background-color: ${lightBackgorund};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.nav-link {
			font-family: ${futuraLight};
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.1em;
			color: ${highEmphasis};
			opacity: 0.95;
			text-decoration: none;
			padding-left: 16px;
			margin-top: auto;
			.log-out {
				&:hover {
					text-decoration: underline;
				}				
			}
		}
		.faq-link {
			font-family: ${futuraLight};
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.1em;
			color: ${highEmphasis};
			opacity: 0.95;
			text-decoration: none;
			padding-left: 16px;
			margin-top: auto;
			&:hover {
				text-decoration: underline;
			}
		}
		button.nav-link {
			padding: 0;
			display: block;
			padding-left: 16px;
			text-align: left;
			&:hover {
				background-color: transparent;
			}
		}
		button.faq-link {
			padding: 0;
			display: block;
			padding-left: 16px;
			background-color: transparant;
			text-align: left;
			&:hover {
				background-color: transparent;
			}
		}
		.MuiList-root {
			padding: 0px;
			position: inherit;
			.user-info {
				display: none;
			}
			.MuiMenuItem-root {
				display: block;
				padding: 0;
				&.active {
					a {
						background-color: ${highEmphasis};
						color: ${white};
						img {
							filter: contrast(0) brightness(100);
						}
						&.nav-link {
							background-color: ${lightBackgorund};
							color: ${highEmphasis}
						}
					}
				}
				.nav-link {
					display: none;
				}
				&.logo-nav {
					border-top: 2px solid ${disabledColor} !important;
				}
				a {
					font-family: 'Futura LT Pro';
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 30px;
					letter-spacing: 0.1em;
					color: ${mediumEmphasis};
					text-decoration: none;
					padding: 16px 18px;
					border-top: 2px solid ${disabledColor};
					display: flex;
					align-items: center;
					&:hover {
						background-color: ${highEmphasis};
						color: ${white};
						img {
							filter: contrast(0) brightness(100);
						}
					}
				}
				.nav-icon {
					margin-right: 20px;
				}
				&.logo-li {
					a {
						border-top: 0px;
						padding: 24px 18px;
						&:hover {
							background: ${lightBackgorund};	
							img {
								filter: none;
							}
						}
					}
				}
			}
		}
		.toggle-button {
			display: none;
		}
		
		@media only screen and (max-width: 991px) {
			height: auto;
			max-width: 100%;
			border-bottom: 2px solid ${disabledColor};
			border-right: 0px;
			.toggle-button {
				display: block;
				position: absolute;
				z-index: 9;
				right: 4.5%;
				font-size: 40px;
				top: 25px;
				cursor: pointer;
			}
			button.nav-link {
				display: none;
			}
			.mobile-nav {
				display: none;
			}
			.mobile-nav {
				.faq-link {
					a {
						font-family: 'Futura LT Pro-light';
					}
				}
				&.active {
					display: block;
					position: absolute;
					width: 100%;
					right: 0;
					background-color: ${lightBackgorund};
					z-index: 2;
					height: 100vh;
					border-bottom: 2px solid ${disabledColor};
					box-shadow: 0px 5px 9px -3px ${customBoxShadowColor};
					.nav-link {
						display: block;
						height: 60px;
    					font-size: 18px;
						width: 100%;
						border-top: 2px solid ${disabledColor};
						border-radius: 0;
					}
				}
			}
			.logout-btn {
				display: none;
			}
			.user-info.active {
				display: flex;
			}
			.nav-link {
				display: none;
				margin-left: 0;
			}
			.MuiMenuItem-root { 
				&.logo-li {
					a {
						width: 210px;
					}
				}
			}
        }

	`,
);

export const UserDiv = styled('div')(
	() => `
        	display: flex;
			border-top: 1px solid ${UserHeading};
			align-items: center;
			padding: 18px 14px 18px 18px;
			@media only screen and (max-width: 991px) {
				display: none;
			}
			&:hover {
				background: ${white};
			}
    `,
);

export const UserImg = styled('img')(
	() => `	
			width: 36px;
    `,
);

export const UserDivImg = styled('div')(
	() => `	
			width: 24%;
			@media only screen and (max-width: 991px) {
				width: 50px;
			}
    `,
);

export const UserDes = styled('div')(
	() => `
           display: flex;
		   flex-direction: column;
    `,
);

export const UserName = styled('h5')(
	() => `
			font-family: ${futuraLight};
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			color: ${UserHeading};
			margin: 0;
    `,
);

export const UserDesignation = styled('p')(
	() => `
			font-family: ${futuraLight};
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 20px;			
			color: ${UserHeading};
			margin: 0;
    `,
);

export const BottomButton = styled('div')(
	() => `	
    `,
);
