/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Layout from '../components/layout';
import { ClearError } from '../redux/slice/authSlice';

export const PrivateRoute = ({ children }) => {
	const { isLoggedIn } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	let location = useLocation();
	useEffect(() => {
		dispatch(ClearError());
	}, [location]);
	if (!isLoggedIn) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} />;
	}
	return <Layout>
		{children}
	</Layout>;
};

export const PublicRoute = ({ children }) => {
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector((state) => state.auth);
	let location = useLocation();


	useEffect(() => {
		dispatch(ClearError());
	}, [location]);
	if (!isLoggedIn) {
		return children;
	}
	return <Navigate to="/" state={{ from: location }} />;
};
