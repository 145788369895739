import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorHandle } from '../../utils/utils';
import * as authService from '../service';

const initialState = {
	loading: false,
	isLoggedIn: false,
	token: null,
	message: '',
	forgot: false,
	forgotMsg: '',
	error: '',
};

export const handleLogin = createAsyncThunk(
	'auth/handleLogin',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await authService.login(data.email, data.password);
			const getUserType = await authService.getUsertypeDetail('users', response.user.uid);
			if (getUserType && getUserType.type === 'admin') {
				return fulfillWithValue(getUserType);
			} else {
				return rejectWithValue('You entered wrong email or password.');
			}
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const handleForgotPassowrd = createAsyncThunk(
	'auth/handleForgotPassowrd',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await authService.forgotPassword(data);
			if (!response) {
				return fulfillWithValue(data);
			}
			return response;
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);
export const handleLogout = createAsyncThunk(
	'auth/handlelogout',
	async({ rejectWithValue }) => {
		try {
			const response = await authService.Logout();
			if (!response) {
				return rejectWithValue();
			}
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		ClearError: (state) => {
			state.error =  '';
			state.message = '';
		},
		closeTooltip: (state) => {
			state.forgotMsg = '';
			state.forgot = false;
		}
	},
	extraReducers: {
		[handleLogin.pending]: (state) => {
			state.isLoggedIn = false;
			state.loading = true;
			state.error = '';
		},
		[handleLogin.fulfilled]: (state) => {
			state.isLoggedIn = true;
			state.loading = false;
			state.error = '';
		},
		[handleLogin.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[handleForgotPassowrd.pending]: (state) => {
			state.loading = true;
			state.forgot = false;
			state.error = '';
			state.forgotMsg = '';
		},
		[handleForgotPassowrd.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = '';
			state.forgot = true;
			state.forgotMsg = `Successfully sent forgot password email to ${action.payload}.`;
		},
		[handleForgotPassowrd.rejected]: (state, action) => {
			state.loading = false;
			state.forgotMsg = '';
			state.forgot = true;
			state.error = action.payload;
		},
		[handleLogout.pending]: (state) => {
			state.loading = false;
			state.error = '';
		},
		[handleLogout.fulfilled]: (state) => {
			state.loading = false;
			// state.isLoggedIn = false;
		},
		[handleLogout.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
			state.isLoggedIn = true;
		}
	}
});

export const { logout, ClearError, closeTooltip } = authSlice.actions;

export default authSlice.reducer;
