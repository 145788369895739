import { styled } from '@mui/material/styles';
import { black, highEmphasis, mediumEmphasis, white } from '../variables';

export const StationDetailStyled = styled('div')(
	() => `	
            .MuiFormControl-root {
                max-width: 320px;
                width: 100%;
                .MuiOutlinedInput-root {
                    height: 44px;
                    margin-bottom: 20px;
                    &.Mui-focused {
                        .MuiOutlinedInput-notchedOutline  {
                            border-color: ${black};
                            border-width: 1px;
                        }    
                    }
                    .MuiOutlinedInput-notchedOutline  {
                        border-radius: 0px;
                    }
                }    
            }
            .MuiPagination-root {
                button.Mui-selected {
                    background-color: ${highEmphasis};
                    color: ${white};
                    border-radius: 5px;
                    border: 1px solid ${highEmphasis};
                    box-shadow: 0 0 8px 0 ${mediumEmphasis};
                }
                button.MuiPaginationItem-page {
                    border-radius: 5px;
                    &:hover {
                        background-color: ${highEmphasis};
                        color: ${white}; 
                    }
                }
                button.MuiPaginationItem-text {
                    border-radius: 5px;
                    &:hover {
                        background-color: ${highEmphasis};
                        color: ${white}; 
                    }
                }
                margin-bottom: 45px;
                .MuiPagination-ul {
                    @media only screen and (max-width: 575px) { 
                        justify-content: center;
                    }
                }
            }
    `,
);

export default StationDetailStyled;