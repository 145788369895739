import firebaseapp from '../../utils/firebase';
import { getStorage, ref, uploadString, getDownloadURL, deleteObject, list } from 'firebase/storage';
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, query, where, deleteDoc } from 'firebase/firestore';


const storage = getStorage();
const db = getFirestore(firebaseapp.app);
// const storageRef = ref(storage, `images/${new Date().getTime() * 1000}.png`);

export async function uploadImage(file, id) {
	const storagePath = ref(storage, `images/${id}/${new Date().getTime() * 1000}.png`);
	await uploadString(storagePath, file, 'data_url');
	return await getDownloadURL(storagePath);
}

export async function addStation(name, data) {
	return await addDoc(collection(db, name), data);
}

export async function getStation(name) {
	const docRef = query(collection(db, name));
	const querySnapshot = await getDocs(docRef);
	const stations = [];
	querySnapshot.forEach((doc) => {
		stations.push({ id: doc.id, ...doc.data() });
	});
	return stations;
}

export async function getStationUserDetail(name, id) {
	const docRef = query(collection(db, name), where('station.id', '==', id), where('isDeleted', '==', false), where('type', '==', 'user'));
	const querySnapshot = await getDocs(docRef);
	const users = [];
	querySnapshot.forEach((doc) => {
		users.push({ id: doc.id, ...doc.data() });
	});
	return users;
}

export async function updateStation(name, data) {
	const docRef = doc(db, name, data.id);
	delete data.id;
	return await setDoc(docRef, data);
}

export async function deleteStation(name, data) {
	await DeleteSelctedStationImg(data.id);
	const docRef = doc(db, name, data.id);
	return await deleteDoc(docRef);
}
export async function DeleteSelctedStationImg(id) {
	const deleteRef = ref(storage, `images/${id}`);
	const firstPage = await list(deleteRef, { maxResults: 100 });
	if (firstPage.items.length) {
		const images = firstPage.items;
		for (let i = 0; i < images.length; i++) {
			const image = images[i];
			await deleteObject(ref(storage, image.fullPath));
		}
		return;
	} return;
}
export async function deleteStationImage(imageUrl) {
	const filePath = ref(storage, imageUrl);
	const deleteRef = ref(storage, filePath);
	return await deleteObject(deleteRef);
}

export function StationImageExistOrNot(imageUrl) {
	const filePath = ref(storage, imageUrl);
	return new Promise((resolve,) => {
		getDownloadURL(ref(storage, filePath.fullPath))
			.then((res) => {
				resolve(res);
			}).catch(() => {
				resolve({ error: 'image does not exist' });
			});
	});
}