import React, { useEffect, useState } from 'react';
import {
	MenuItem,
	MenuList,
	Button
} from '@mui/material';
import { SideMenu, UserDiv, UserImg, UserDes, UserName, UserDesignation, UserDivImg, BottomButton } from '../../../styles/components/sidebar';
import logo from '../../../assets/images/logo-main.png';
import groupsIcon from '../../../assets/images/Icons/groups.png';
import userIcon from '../../../assets/images/Icons/users.png';
import Stations from '../../../assets/images/Icons/stations.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/slice/authSlice';
import { useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import userAvatar from '../../../assets/images/user-avatar.png';

const Sidebar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let params = useLocation();
	const [isActive, setActive] = useState('false');
	const [isActiveMenu, setIsActiveMenu] = useState('');

	const activateLasers = () => {
		setActive(!isActive);
	};

	useEffect(() => {
		if (params.pathname === '/') {
			setIsActiveMenu('createStation');
		} else {
			setIsActiveMenu(params.pathname);
		}
	}, [params]);

	const activeTabShow = (title) => {
		return isActiveMenu.includes(title);
	};
	let mediaQuery = window.matchMedia('(max-width: 991px)');
	if (mediaQuery.matches) {
		!isActive ? document.querySelector('body').style.height = '100vh' : document.querySelector('body').style.maxHeight = 'auto';
		!isActive ? document.querySelector('body').style.overflowY = 'hidden' : document.querySelector('body').style.overflowY = 'auto';
		!isActive ? document.querySelector('body').style.position = 'fixed' : document.querySelector('body').style.position = 'unset';
		!isActive ? document.querySelector('body').style.left = '0' : document.querySelector('body').style.left = 'unset';
		!isActive ? document.querySelector('body').style.right = '0' : document.querySelector('body').style.right = 'unset';
	}
	else {
		document.querySelector('body').style.maxHeight = 'auto';
	}

	return (
		<SideMenu>
			<MenuIcon className='toggle-button' onClick={activateLasers} />
			<MenuList >
				<MenuItem className='logo-li'>
					<Link to="/" ><img src={logo} alt="" /></Link>
				</MenuItem>
				<div className={isActive ? 'mobile-nav' : 'active  mobile-nav'}>
					<MenuItem onClick={activateLasers} className={ activeTabShow('createStation') || activeTabShow('editSation') ? 'active': ''}>
						<Link to="/"><img src={Stations} alt="" className='nav-icon'  /> Stations</Link>
					</MenuItem>
					<MenuItem onClick={activateLasers} className={activeTabShow('users') || activeTabShow('createUser') || activeTabShow('editUser') ? 'active': ''}>
						<Link to="/users"><img src={userIcon} alt="" className='nav-icon' /> Users</Link>
					</MenuItem>
					<MenuItem onClick={activateLasers} className={activeTabShow('groups') || activeTabShow('createGroup') || activeTabShow('editGroup') ? 'active': ''}>
						<Link to="/groups"><img src={groupsIcon} alt="" className='nav-icon' /> Groups</Link>
					</MenuItem>
					{window.innerWidth < 991 && <MenuItem onClick={activateLasers} className={activeTabShow('faq') ? 'faq-link active': 'faq-link'}>
						<Link to="/faq" alt="" className='nav-Faq-link'>FAQ</Link>
					</MenuItem>}
					<MenuItem onClick={activateLasers} className={activeTabShow('') || activeTabShow('') || activeTabShow('') ? 'log-nav': ''}>
						<Button className='nav-link log-out' onClick={() => {dispatch(logout(false));}}>Logout</Button>
					</MenuItem>
					<UserDiv className={isActive ? 'user-info': 'user-info active'}>
						<UserDivImg>
							<UserImg src={userAvatar} alt="" />
						</UserDivImg>
						<UserDes>
							<UserName>Oana Maria Sofronia</UserName>
							<UserDesignation>Manger</UserDesignation>
						</UserDes>
					</UserDiv>
				</div>
			</MenuList>
			<BottomButton className='nav-link'>
				<Button className='faq-link' onClick={() => navigate('/faq')}>
					FAQ
				</Button>
				<Button className='nav-link log-out' onClick={() => {dispatch(logout(false));}}>
					Logout
				</Button>
			</BottomButton>
			<UserDiv>
				<UserDivImg>
					<UserImg src={userAvatar} alt="" />
				</UserDivImg>
				<UserDes>
					<UserName>Oana Maria Sofronia</UserName>
					<UserDesignation>Manager</UserDesignation>
				</UserDes>
			</UserDiv>
		</SideMenu>
	);
};

export default Sidebar;
