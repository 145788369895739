import { configureStore } from '@reduxjs/toolkit';
import auth from '../redux/slice/authSlice';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import station from '../redux/slice/stationSlice';
import user from '../redux/slice/userSlice';
import groups from '../redux/slice/groupSlice';
import Faq from  '../redux/slice/faqSlice';

const reducers = combineReducers({
	auth,
	station,
	user,
	Faq,
	groups
});

const rootReducer = (state, action) => reducers(state, action);

const persistConfig = {
	key: 'root',
	storage,
	whiteList: ['auth'],
	blackList: ['sation']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
});
export const persistor = persistStore(store);