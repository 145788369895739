import React from 'react';
import ErrorImg from '../../assets/images/error.png';
import { ErrorContent, ErrImg, ErrorMessage } from '../../styles/components/ErrorMsg.js';

const ErrorMsg = ({ error, image }) => {
	return (
		<ErrorContent className={image ? 'upload-error error-msg-div' : 'error-msg-div'}>
			<ErrImg src={ErrorImg} />
			<ErrorMessage >{error}</ErrorMessage>
		</ErrorContent>
	);
};
export default ErrorMsg;
