import React, { useState, useEffect } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
import {
	PageHeader,
	PageTitle,
	FormDiv,
	FiledInputText,
	FieldInputTextArea,
	Faqform,
	FaqButton,
	FieldInput,
	AddunderLine,
	FaqList,
} from '../../styles/pages/faq';
import { useDispatch } from 'react-redux';
import SimpleBackdrop from '../../components/loader';
import { addNewFaq, getFAQDetils } from '../../redux/slice/faqSlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorMsg from '../../components/ErrorMsg';

const FAQ = () => {
	const dispatch =  useDispatch();
	const [questions, setQuestions] = useState([]);
	const [loader, setLoader] = useState(false);
	const [isOpenAlert, setIsOpenAlert] = useState(false);
	const [error, setError] = useState('');
	const [vaild, setVaild] = useState(false);
	const [faqList, setFfaqList] = useState([]);

	useEffect(() => {
		setLoader(true);
		getFAQData();
	}, []);

	const getFAQData = () => {
		dispatch(getFAQDetils(handleData));
	};
	const handleData = (data) => {
		setLoader(false);
		setQuestions(data);
		setFfaqList(data);
	};
	const addQuestions = () => {
		const question = [...questions];
		question.push({
			question: '',
			answer: '',
			id: question.length + 1,
			addNew: true,
		});
		setQuestions(question);
	};

	const handleSubmit = (e) => {
		setError('');
		e.preventDefault();
		if (!disbledSubmitBtn()) {
			const validData = questions.find(el => el.question === '' || el.answer === '');
			if (!validData) {
				submitData();
			} else if (validData && validData.isDeleted) {
				submitData();
			} else {
				setVaild(true);
			}
		}
	};
	const submitData = () => {
		const detail = questions.filter(el => el.question || el.answer || el.isDeleted );
		setLoader(true);
		setVaild(false);
		dispatch(addNewFaq({ detail, response: ErrorHandle }));
	};
	const ErrorHandle = (res) => {
		if (res.success) {
			setLoader(false);
			setIsOpenAlert(true);
			getFAQData();
		}
		if (res.error) {
			setLoader(false);
			setIsOpenAlert(true);
			setError(res.error);
		}
	};

	const handleChange = (e, data) => {
		const { name, value } = e.target;
		const question = questions.map(el => {
			if (el.id === data.id) {
				el = { ...data, [name]: value };
			}
			return el;
		});
		setQuestions(question);
	};

	const handleDelete = (data) => {
		const question = questions.map(el => {
			if (el.id === data.id) {
				el = { ...data, isDeleted: true };
			}
			return el;
		});
		setQuestions(question);
	};
	const disbledSubmitBtn = () => {
		if (faqList === questions) {
			return true;
		}
		const valid =  questions.filter((el) => { return el.answer === '' || el.question === ''; });
		return valid.length ? true : false;
	};

	const getQuestionData = () => {
		return questions.filter(el => !el.isDeleted);
	};
	return (
		<div>
			{loader && <SimpleBackdrop />}
			<PageHeader>
				<PageTitle>FAQ</PageTitle>
			</PageHeader>
			<Faqform onSubmit={handleSubmit}>
				{getQuestionData().map((question, index) => {
					return (
						<FaqList key={index}>
							<Grid container>
								<Grid item lg={12} md={12} xs={12}>
									<FormDiv>
										<FiledInputText>Question</FiledInputText>
										<FieldInput
											type="text"
											name='question'
											value={question.question}
											placeholder="Question"
											onChange={(e) => handleChange(e, question)}
										/>
										{!question.question && vaild && <ErrorMsg error={'Question is required'} />}
									</FormDiv>
									<FormDiv>
										<FiledInputText>Answer</FiledInputText>
										<FieldInputTextArea
											type="text"
											name='answer'
											value={question.answer}
											placeholder="Answer"
											onChange={(e) => handleChange(e, question)}
										/>
										{!question.answer && vaild && <ErrorMsg error={'Answer is required'} />}
									</FormDiv>
									<Grid>
										<DeleteOutlineIcon className='deleteIcon' onClick={() => handleDelete(question)} />
										{getQuestionData().length - 1 === index &&
											<AddIcon className='addIcon' onClick={() => addQuestions()} />
										}
									</Grid>
								</Grid>
							</Grid>
							<AddunderLine className='unerline' />
						</FaqList>
					);
				})}
				<Grid container>
					{getQuestionData().length === 0 &&
						<Grid item lg={12} md={12} xs={12}>
							<AddIcon className='addIcon' onClick={() => addQuestions()} />
						</Grid>
					}
					{/* <Grid item lg={12} md={12} xs={12}>
						<FormDiv>
							<FiledInputText>Question</FiledInputText>
							<FieldInput
								type="text"
								name='question'
								value={inputValue.question}
								placeholder="Question"
								onChange={(e) => handleChangeQuestion(e)}
							/>
							{!inputValue.question && inputVaild && <ErrorMsg error={'Question is required'} />}
						</FormDiv>
						<FormDiv>
							<FiledInputText>Answer</FiledInputText>
							<FieldInputTextArea
								type="text"
								name='answer'
								value={inputValue.answer}
								placeholder="Answer"
								onChange={(e) => handleChangeQuestion(e)}
							/>
							{!inputValue.answer && inputVaild && <ErrorMsg error={'Answer is required'} />}
						</FormDiv>
					</Grid>
					<Grid item lg={12} md={12} xs={12}>
						<AddIcon className='deleteIcon' onClick={() => addQuestions()} />
					</Grid> */}
				</Grid>
				<Grid container className='add-faq'>
					<Grid item lg={12} md={12} xs={12}>
						<FaqButton disabled={disbledSubmitBtn()} className="main-btn" type='submit'>
							Submit
						</FaqButton>
					</Grid>
				</Grid>
			</Faqform>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isOpenAlert} autoHideDuration={6000} onClose={() => setIsOpenAlert(false)}>
				<Alert onClose={() => setIsOpenAlert(false)} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
					{error ? error : 'Faq data updated successfully.'}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default FAQ;