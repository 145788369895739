import { styled } from '@mui/material/styles';
import { disabledColor, highEmphasis, optima, futura, black, dividerColor, futuraLight, lightBackgorund, white, lightBorderColor, lightBorderInnerColor, highEmphasisHover } from '../variables';

export const CreateGroupStyled = styled('div')(
	() => `
	`,
);

export const PageHeader = styled('div')(
	() => `
            display: flex;	
            justify-content: start;
            align-items: center;
            height: 91px;
            border-bottom: 2px solid ${disabledColor};
            margin: 0 -40px;
            padding: 0 40px;
            position: fixed;
            top: 0;
            width: 100%;
            background-color: ${white};
            z-index: 2;
            a {
                color: ${highEmphasis}; 
                margin-right: 15px;
               
                &:hover {
                    color: ${disabledColor};
                    background-color: transparent;
                }
            }
            @media only screen and (max-width: 991px) { 
                position: relative;
                z-index: 0;
                margin: 0 -18px;
                padding: 0 18px;
            }
            @media only screen and (max-width: 575px) { 
                &.delete-header {
                    align-items: baseline;
                    height: 150px;
                }
            }
    `,
);

export const PageTitle = styled('h3')(
	() => `
            font-family: ${optima};
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
            letter-spacing: 0.25px;
            color: ${highEmphasis};
            white-space: nowrap;
            overflow-x: hidden;
            width: 65%;
            text-overflow: ellipsis;
            @media only screen and (max-width: 1367px) { 
                margin-top: 24px;
                width: 50%;
            }
            @media only screen and (max-width: 1200px) { 
                margin-top: 24px;
                width: 30%;
            }
            @media only screen and (max-width: 991px) { 
                margin-top: 24px;
                width: 50%;
            }
            @media only screen and (max-width: 575px) { 
                margin-top: 24px;
                width: 100%;
            }
            @media only screen and (max-width: 355px) { 
                font-size: 26px;
            }
    `,
);

export const ContentTitle = styled('h4')(
	() => `
            font-family: ${futura};
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: ${black};
            padding: 16.5px 0 16.5px 15px;
            margin: 10px 0 25px 0;
            border-bottom: 2px solid ${dividerColor};
            @media only screen and (max-width: 355px) { 
                font-size: 18px;
            }
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                border-bottom: 2px solid ${black};
                width: 160px;                
            }
    `,
);

export const FormDiv = styled('div')(
	() => `
            max-width: 680px;
    `,
);

export const LogoDiv = styled('div')(
	() => `
            overflow: hidden;
            max-width: 486px;
            text-align: center;
            max-height: 251px;
            margin-bottom: 10px;
            background: #C4C4C4;
            margin-left: auto;
            @media only screen and (max-width: 767px) { 
                margin-left: 0;
            }
            img {
                width: 100%;
            }
    `,
);

export const FiledInputText = styled('p')(
	() => `
            font-family: ${futuraLight};
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: ${highEmphasis};
            margin-bottom: 5px;
            margin-top: 0;
    `,
);

export const ImgDes = styled('p')(
	() => `
            margin-top: 35px;
            font-family: ${futuraLight};
            font-size: 14px;
            line-height: 2.5em;
            letter-spacing: 0.1em;
            color: ${black};
            margin-bottom: 30px;
            max-width: 486px;
            margin-left: auto;
            @media only screen and (max-width: 767px) { 
                margin-left: 0;
            }
    `,
);

export const InfoTitle = styled('h5')(
	() => `
            font-family: ${futuraLight};
            font-size: 20px;
            line-height: 20px;    
            color: ${highEmphasis};
            margin-left: 24px;
            margin-bottom: 20px;
            margin-top: 30px;
    `,
);

export const PageInfo = styled('div')(
	() => `
            border: 1px solid ${lightBorderColor};
            padding: 0;
            box-sizing: border-box;
            padding-bottom: 10px;
            margin-bottom: 25px;
            max-width: 680px;
            overflow-y: scroll;
            max-height: 465px;
    `,
);


export const InfoList = styled('div')(
	() => `
            padding: 0px 18.5px 0px 33px;
            height: 100%;
            font-family: ${futuraLight};
            line-height: 22px;
    `,
);


export const InfoListItem = styled('div')(
	() => `
            border-bottom: 1px solid ${lightBorderInnerColor};
            height: 63px;
            position: relative;
            line-height: 62px;
            padding-left: 13px;
            font-family: ${futuraLight};
            font-size: 16px;    
            color: ${highEmphasis};
            &.disabled {
                opacity: .6;
            }
           .MuiCheckbox-root {
                position: absolute;
                left: -36px ;
                top: 11px;
                .MuiSvgIcon-root {
                    color: ${lightBorderInnerColor};
                    width: .95em;
                    height: .95em;
                }
            }
            span.Station_name {
                white-space: nowrap;
                overflow-x: hidden;
                width: 100%;
                text-overflow: ellipsis;
                display: block;
            }
    `,
);

export const ButtonGroup = styled('div')(
	() => `
            display: flex;
            align-items: center;
            max-width: 680px;
            justify-content: space-between;
            flex-wrap: wrap;
            .main-btn {
                display: flex;
                align-items: center;
                width: 49.15% !important;
                min-width: 230px;
                margin-bottom: 25px;
            }
            @media only screen and (max-width: 767px) { 
                .main-btn {
                    width: 85%;
                }
            }
            @media only screen and (max-width: 575px) { 
                justify-content: center;
            }
    `,
);

export const FieldInput = styled('input')(
	() => `
            height: 40px;
            border: 1px solid ${disabledColor};
            color: ${highEmphasis};
            border-radius: 0;
            width: 100%;
            padding-left: 15px;
            font-family: ${futuraLight};
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            box-sizing: border-box;
            &:focus-visible {
                outline-style: none;
                border-color: ${black};
            }
    `,
);

export const FieldInputTextArea = styled('textarea')(
	() => `
            min-height: 40px;
            border: 1px solid ${disabledColor};
            color: ${highEmphasis};
            border-radius: 0;
            width: 100%;
            max-width: 100%;
            padding-top: 8px;
            padding-left: 15px;
            font-family: ${futuraLight};
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            box-sizing: border-box;
            &:focus-visible {
                outline-style: none;
                border-color: ${black};
            }
    `
);

export const PageContent = styled('div')(
	() => `
            margin-top: 102px;
            .pos-relative {
                position: relative;
                .error-msg-div {
                    position: absolute;
                    left: 15px;
                    margin-top: 8px;
                }
            }
            .main-btn {
                display: block;
                border: 1px solid ${highEmphasis};
                border-radius: 0;
                max-width: 680px;
                width: 100%;
                max-height: 48px;
                height: 100%;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                margin-bottom: 80px;
                margin-top: 24px;
                text-transform: none;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }
            .delete-btn {
                max-width: 214px;
                max-height: 48px;
                height: 100%;
                width: 100%;
                border: 1px solid ${highEmphasis};
                border-radius: 0px;
                padding: 0;
                background-color: ${highEmphasis};
                top: 20px;
                z-index: 4;
                &:hover {
                    background-color: ${highEmphasisHover};
                } 
                position: absolute;
                right: 56px;
                font-family: ${futura};
                font-size: 20px;
                text-decoration: none;
                text-transform: none;
                line-height: 45px;
                letter-spacing: 0.1em;
                color: ${white};
                    
                @media only screen and (max-width: 991px) { 
                    z-index: 0;
                    top: 111px;
                    right: 20px;
                }
                @media only screen and (max-width: 575px) { 
                    top: 173px;
                    left: 20px;
                    max-width: 180px;
                    max-height: 44px;
                    font-size: 18px;
                }
                @media only screen and (max-width: 355px) { 
                   a {
                        font-size: 18px;
                   } 
                }
            }
            .custom-select {
                max-width: 680px;
                width: 100%;
                .field-input {
                    width: 100%;
                    max-width: 100%;
                    border: white;
                    margin-bottom: 16px;
                    .MuiSelect-select {
                        padding: 11px 26px 11px 15px;
                    }
                }
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 0;
                }
                .Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: ${highEmphasis};
                        border-width: 1px;
                        border-radius: 0;
                    }
                }
            }
            .upload-btn {
                display: block;
                width: 100%;
                border: 1px solid ${black};
                border-radius: 0;
                min-height: 48px;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                text-transform: none;
                max-width: 486px;
                @media only screen and (max-width: 767px) { 
                    margin-left: 0;
                }
                margin-left: auto;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }

            @media only screen and (min-width: 1200px) { 
                .MuiGrid-grid-md-6 {
                    padding-right: 10px;
                }
            }
            @media only screen and (max-width: 991px) { 
                margin-top: 0;
                .new-station-button {
                    margin-bottom: 40px;
                }
                .MuiGrid-grid-md-6 {
                    margin-bottom: 10px;
                }
            }
    `,
);
