import { styled } from '@mui/material/styles';
import { highEmphasis, disabledColor, highEmphasisHover, lightBackgorund, black, optima, futura, futuraLight, sucsessColor } from '../variables';

export const ForgotPasswordCard = styled('div')(
	() => `
		background-color: ${lightBackgorund};
		height: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		.forgot-container {
			border: 1px solid ${black};
			text-align: center;
			height: 98vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			.logo-div {
				width: 100%;
				padding: 6px 0;
				border-bottom: 0.5px solid ${highEmphasis};
				.logo {
					max-width: 335px;
					width: 90%;
				}
			}
			.content-div {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				.success-msg {
					color: ${sucsessColor};
					font-family: ${futuraLight};
					font-size: 16px;
					margin-top: 5px;
					margin-bottom: 20px;
				}
				.lead {
					margin-bottom: 40px;
					font-family: ${futuraLight};
					font-style: normal;
					font-size: 16px;
					line-height: 19px;
					max-width: 440px;
				}
				.page-title {
					font-style: normal;
					font-weight: 400;
					font-size: 28px;
					line-height: 34px;
					font-family: ${optima};
					margin-bottom: 130px;
				}
				.forgot-button {
					background: ${highEmphasis};
					border: 1px solid ${highEmphasis};
					color: white;
					border-radius: 0;
					display: block;
					text-align: center;
					margin: 0 auto;
					width: 446px;
					height: 40px;
					margin-bottom: 40px;
					text-transform: none;
				}
				.forgot-button:hover {
					background-color: ${highEmphasisHover};
				}
				.input-login {
					border-radius: 0;
					display: block;
					margin: 0 auto;
					max-width: 440px;
					width: 100%;
					height: 40px;
					outline: none;
					margin-bottom: 16px;
					box-shadow: none;
					border: 1px solid ${highEmphasis};
					text-align: center;
					font-family: ${futura};
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: ${disabledColor};
				}
			}
		}

		.close-icon {
			width: 16px;
			position: absolute;
			top: 11%;
			right: 10%;
		}

		@media only screen and (max-width: 767px) {
			.forgot-container {
				.content-div {
					.input-login {
						max-width: 240px;
					}
					.forgot-button {
						width: 244px;
					}
					.lead {
						max-width: 240px;
					}
				}
			} 
		}
	`,
);
