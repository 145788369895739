/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
	Grid,
	Button,
	Input,
	Select,
	FormControl,
	MenuItem,
	OutlinedInput,
	Box,
	Chip,
} from '@mui/material';
import stationLogo from '../../assets/images/create-station-logo.png';
import { CreateGroupStyled, PageHeader, PageContent, ContentTitle, PageTitle, FormDiv, LogoDiv, FiledInputText, ImgDes, FieldInput, FieldInputTextArea } from '../../styles/pages/createGroup';
import StationDetail from '../../components/Group/stationDetail';
import ConfirmDelete  from '../../components/delete-component';
import {
	addNewGroup,
	getGroupDetail,
	updateGroupDetail,
	deleteGroupDetail,
	ClearError
} from '../../redux/slice/groupSlice';
import SimpleBackdrop from '../../components/loader';
import { getStationList } from '../../redux/slice/stationSlice';
import ErrorMsg from '../../components/ErrorMsg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CreateGroup = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { stations } = useSelector(state => state.station);
	const groupReducer = useSelector(state => state.groups);
	const [stationDetail, setStationDetail] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [selectedStation, setSelectedSation] = useState([]);

	const [inputValue, setInputValue] = useState({
		groupName: '',
		groupLocation: '',
		groupImage: '',
		groupDescription: '',
		stations: [],
	});
	const [pageTitle, setPageTitle] = useState('');
	const [errors, setErrors] = useState({});
	const [isOpenAlert, setIsOpenAlert] = useState(false);

	useEffect(() => {
		dispatch(ClearError());
	}, []);
	useEffect(async() => {
		if (params.id) {
			setSelectedGroup(params.id);
			dispatch(getGroupDetail({ id: params.id, fetchDetail }));
		} else {
			setSelectedGroup('');
			dispatch(getStationList());
		}
	}, [params]);
	const fetchDetail = ({ detail, stationList }) => {
		setPageTitle(detail.groupName);
		const allStation = [];
		stationList.forEach(el => {
			const StationDe = { ...el };
			if (el.groupId) {
				if (el.groupId === detail.id) {
					StationDe.checked = true;
					StationDe.disabled = false;
				} else {
					StationDe.checked =  false;
					StationDe.disabled = true;
				}
			} else {
				StationDe.checked = false;
				StationDe.disabled = false;
			}
			allStation.push(StationDe);
		});
		allStation.sort(function(a, b){return b.checked - a.checked; });
		setSelectedSation(allStation);
		setStationDetail(allStation);
		setInputValue({
			...inputValue,
			...detail,
		});
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setInputValue({
			...inputValue, [name]: value,
		});
	};

	const uploadImage = () => {
		document.getElementById('inputGroupImage').click();
	};
	const handleUpload = (event) => {
		const image = event.target.files[0];
		if (image) {
			const reader = new FileReader();
			const error = { ...errors };
			let size = 0;
			if (image) {
				size = Math.ceil(image.size / (1024 * 1024));
			}
			if (!image.name.match(/\.(png)$/)) {
				error['image'] = 'Please select valid image. only png format allowed';
			} else if (size > 5) {
				error['image'] = 'Please select maximum 5mb image';
			} else {
				error['image'] = '';
				reader.readAsDataURL(image);
				reader.onload = () => {
					const imageUrl = reader.result;
					const img = new Image;
					img.src = imageUrl;
					const maxWidth = 1920;
					const maxHeight = 1080;
					img.onload = () => {
						if (img.width > maxWidth || img.height > maxHeight) {
							error['image'] = `Please choose one with maximum dimensions of ${maxWidth}x${maxHeight}.`;
							setErrors({ ...error });
						} else {
							error['image'] = '';
							setErrors({ ...error });
							if (inputValue.groupImage.includes('firebasestorage')) {
								setInputValue({
									...inputValue,
									oldImageUrl: inputValue.groupImage,
									groupImage: imageUrl,
								});
							} else {
								getImageUrl(imageUrl);
							}
						}
					};
				};
			}
			setErrors(error);
		}
	};

	const getImageUrl = (image) => {
		setInputValue({
			...inputValue,
			groupImage: image,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!selectedGroup) {
			if (isValidate()) {
				const Stations = [];
				inputValue.stations.map((el) => {
					const data = {
						id: el.id,
						stationName: el.stationName,
						stationImage: el.stationImage,
					};
					Stations.push(data);
					return el;
				});
				const detail = {
					...inputValue,
					stations: inputValue.stations,
				};
				const groupImage = inputValue.groupImage;
				dispatch(addNewGroup({ detail, groupImage, selectedStation: inputValue.stations, navigate: navigateGroupPage }));
			}
		} else {
			const Stations = [];
			stationDetail.forEach(el => {
				if (el.checked) {
					Stations.push({
						id: el.id,
						stationLink: el.stationLink ? el.stationLink : '',
						stationName: el.stationName ? el.stationName : '',
						stationImage: el.stationImage ? el.stationImage: '',
					});
				}
			});
			if (isValidate(Stations)) {
				const detail = {
					id: selectedGroup,
					groupName: inputValue.groupName,
					groupImage: inputValue.groupImage,
					groupLocation: inputValue.groupLocation,
					stations: Stations,
					groupDescription: inputValue.groupDescription,
					isDeleted: false,
					createdDate: inputValue.createdDate,
				};
				if (inputValue.oldImageUrl) {
					detail.oldImageUrl = inputValue.oldImageUrl;
				}
				dispatch(updateGroupDetail({ detail, selectedStation: getSelectedStation(), navigate: navigateGroupPage }));
			}
		}
	};
	const navigateGroupPage = () => {
		navigate('/groups');
	};

	const getSelectedStation = () => {
		const selectStation = [];
		stationDetail.forEach(el => {
			if (el.groupId === selectedGroup || el.checked) {
				if (!el.groupId && el.checked) {
					el.groupId = selectedGroup;
				}
				selectStation.push(el);
			}
		});
		return selectStation;
	};
	const deleteGroup = () => {
		const detail = {
			...inputValue,
			isDeleted: true,
			selectStation: getSelectedStation(),
		};
		setIsOpenAlert(!isOpenAlert);
		dispatch(deleteGroupDetail({ detail, navigate: navigateGroupPage }));
	};

	const isValidate = (stations) => {
		let valid = true;
		const error = {};
		const inputs = inputValue;
		if (!inputs['groupName']) {
			valid = false;
			error['groupName'] = 'Please enter Group Name.';
		} else {
			error['groupName'] = '';
		}
		if (!inputs['groupLocation']) {
			valid = false;
			error['groupLocation'] = 'Please enter Group Location.';
		}
		else {
			error['groupLocation'] = '';
		}
		if (!inputs['groupDescription']) {
			valid = false;
			error['groupDescription'] = 'Please enter Group Description.';
		} else {
			error['groupDescription'] = '';
		}
		if (!selectedGroup) {
			if (!inputs['stations'].length) {
				valid = false;
				errors['stations'] = 'Please Select Stations.';
			} else {
				error['stations'] = '';
			}
		} else {
			if (!stations.length) {
				valid = false;
				errors['stations'] = 'Please Select Stations.';
			} else {
				error['stations'] = '';
			}
		}
		if (!inputs['groupImage']) {
			valid = false;
			errors['image'] = 'Please upload Group Image.';
		}
		setErrors({
			...errors,
			...error
		});
		return valid;
	};

	const handleChangeSation = (station) => {
		const updateStations = [];
		selectedStation.forEach((el) => {
			if (el.id === station.id) {
				el.checked = !station.checked;
			}
			updateStations.push(el);
		});
		setSelectedSation(updateStations);
	};

	const handleSearch = (searchText) => {
		const filterdData = stationDetail.filter((item) => {
			return  Object.values([item.stationName]).join('').toLowerCase().includes(searchText.toLowerCase());
		});
		setSelectedSation(filterdData);
	};

	const getDisabledStation = (station) => {
		if (selectedGroup === station.groupId) {
			return false;
		}
		return station.groupId ? true : false;
	};

	return (
		<CreateGroupStyled>
			{groupReducer.groupImageLoader && <SimpleBackdrop />}
			{groupReducer.loading && <SimpleBackdrop />}
			<PageHeader className={selectedGroup ? 'delete-header'  : ''}>
				<Link to="/groups" >
					<ArrowBackIosIcon />
				</Link>
				<PageTitle>{selectedGroup ? pageTitle  : 'Create new Group'}</PageTitle>
			</PageHeader>
			<PageContent>
				<ContentTitle >Group details</ContentTitle>
				{selectedGroup && <Button className='delete-btn' onClick={() => setIsOpenAlert(true)}>
					Delete Group
				</Button>}
				<form>
					<Grid container spacing={2}>
						<Grid item lg={8} md={7} xs={12}>
							<FormDiv>
								<FiledInputText>Group Name</FiledInputText>
								<FieldInput
									type="text"
									name='groupName'
									value={inputValue.groupName}
									onChange={handleChange}
								/>
								{errors && errors.groupName && <ErrorMsg error={errors.groupName} />}
							</FormDiv>
							<FormDiv>
								<FiledInputText>Group Location</FiledInputText>
								<FieldInput
									type="text"
									name='groupLocation'
									value={inputValue.groupLocation}
									onChange={handleChange}
								/>
								{errors && errors.groupLocation && <ErrorMsg error={errors.groupLocation} />}
							</FormDiv>
							<FormDiv>
								<FiledInputText>Group Description</FiledInputText>
								<FieldInputTextArea
									name="groupDescription"
									value={inputValue.groupDescription}
									rows="4" cols="50"
									onChange={handleChange}>
								</FieldInputTextArea>
								{errors && errors.groupDescription && <ErrorMsg error={errors.groupDescription} />}
							</FormDiv>
							{!selectedGroup &&
								<>
									<FiledInputText>Select Stations</FiledInputText>
									<FormControl className="custom-select">
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={inputValue.stations}
											multiple={true}
											onChange={handleChange}
											name="stations"
											className="field-input"
											input={<OutlinedInput id="select-multiple-chip" />}
											renderValue={(selected) => (
												<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
													{selected.map((value, index) => (
														<Chip key={index} label={value.stationName} />
													))}
												</Box>
											)}
											MenuProps={MenuProps}
										>
											{
												stations && stations.map((station, index) => {
													return (
														<MenuItem key={index} disabled={getDisabledStation(station)} value={station}>{station.stationName}</MenuItem>
													);
												})
											}
										</Select>
										{errors && errors.stations && <ErrorMsg error={errors.stations} />}
									</FormControl>
								</>
							}
							{selectedGroup && <StationDetail stations={selectedStation} getDisabledStation={getDisabledStation} handleUpdate={handleChangeSation} handleSearch={handleSearch} />}
							{errors && errors.stations && selectedGroup && <ErrorMsg error={errors.stations} />}
						</Grid>

						<Grid item lg={4} md={5} sm={7} xs={12}>
							<LogoDiv>
								{inputValue.groupImage  ? <img src={inputValue.groupImage} alt="" /> : <img src={stationLogo} alt="" />}
							</LogoDiv>
							{errors && errors.image && <ErrorMsg error={errors.image} image={true} />}
							<Input
								accept=".png"
								style={{ display: 'none' }}
								type="file"
								onChange={handleUpload}
								id="inputGroupImage"
							/>
							<Button
								className="upload-btn"
								onClick={uploadImage}
							>
								{groupReducer.groupImageLoader ? 'Loading...' : selectedGroup ? 'Change Logo' : 'Upload Logo'}
							</Button>
							<ImgDes>
								Image format accepted: png <br />
								Minimum recommended size: 768X432px <br />
								Maximum file size: 5mb
							</ImgDes>
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pos-relative">
						{groupReducer && groupReducer.error && <ErrorMsg error={groupReducer.error} />}
						<Grid item lg={8} md={7} xs={12}>
							<Button
								disabled={groupReducer.loading}
								className="main-btn"
								onClick={(e) => handleSubmit(e)}
							>
								{selectedGroup ? 'Edit Group'  : 'Create new Group'}
							</Button>
						</Grid>
					</Grid>

				</form>
				<ConfirmDelete open={isOpenAlert} title='Are you sure you want to delete this group?' handleOk={() => deleteGroup()} setOpen={() => setIsOpenAlert(!isOpenAlert)} />
			</PageContent>
		</CreateGroupStyled>
	);
};

export default CreateGroup;
