import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorHandle } from '../../utils/utils';
import * as userService  from '../service';
import CryptoJS from 'crypto-js';

const initialState = {
	loading: false,
	users: [],
	userLoader: false,
	error: '',
	message: '',
};

export const addUser = createAsyncThunk(
	'user/addUser',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail } = data;
			const existUsername = await userService.findExistUserName('users', detail);
			if (existUsername.length && !existUsername[0].isDeleted) {
				return rejectWithValue('Username already existing. please enter unique name');
			} else if ( existUsername[0]) {
				const updateDetail = {
					...existUsername[0],
					...detail,
				};
				const response = await userService.updateUser('users', updateDetail);
				if (!response) {
					return data.navigateUserPage(), fulfillWithValue();
				}
			} else {
				const response = await userService.addUser('users', { ...data.detail, type: 'user' });
				if (!response) {
					return rejectWithValue();
				}
				return data.navigateUserPage(), fulfillWithValue({ ...data, id: response.id });
			}
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const getUserList = createAsyncThunk(
	'user/getUserList',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await userService.getUser('users');
			const users = [];
			response.forEach(el => {
				users.push({ ...el, checked: false });
			});
			return fulfillWithValue(users);
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

export const getUserDetail = createAsyncThunk(
	'user/getUserDetail',
	async(data, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await userService.getUserDetail('users', data.id);
			let newResponse = {};
			const decryptPassword = CryptoJS.AES.decrypt(response.password, '123456').toString(CryptoJS.enc.Utf8);
			newResponse = { ...response, password: decryptPassword };
			if (newResponse) {
				return data.fetchDetail(newResponse), fulfillWithValue(data);
			}
			return fulfillWithValue(data);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const updateUserDetail = createAsyncThunk(
	'user/updateUserDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const existUser = await userService.findExistUserName('users', data.detail);
			const exists = existUser.find(el => el.id !== data.detail.id);
			if (exists) {
				return rejectWithValue('Username already existing. please enter unique name');
			}
			else {
				const response = await userService.updateUser('users', data.detail);
				if (!response) {
					return data.navigateUserPage(), fulfillWithValue();
				}
			}
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);
export const deleteMultipleUser = createAsyncThunk(
	'user/deleteMultipleUser',
	async(data, { rejectWithValue, fulfillWithValue, dispatch }) => {
		try {
			const detail = data.users;
			for (let i = 0; i < detail.length; i++) {
				const user = detail[i];
				user.isDeleted = true;
				await userService.updateUser('users', user);
			}
			dispatch(getUserList());
			return fulfillWithValue();
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const deleteUser = createAsyncThunk(
	'user/deleteUser',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await userService.updateUser('users', data.detail);
			if	(!response) {
				return data.navigateUserPage(), fulfillWithValue();
			}
		}
		catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
	},
	extraReducers: {
		[addUser.pending]: (state) => {
			state.loading = true;
			state.error = '';
			state.message = '';
		},
		[addUser.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'New User created successfully';
		},
		[addUser.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
		},
		[getUserList.pending]: (state) => {
			state.users = [];
			state.loading = true;
			state.message = '';
			state.error = '';
			state.userLoader = true;
		},
		[getUserList.fulfilled]: (state, action) => {
			state.loading = false;
			state.message = 'Users listed succesfully';
			state.users = action.payload;
			state.userLoader = false;
		},
		[getUserList.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
			state.userLoader = false;
		},
		[getUserDetail.pending]: (state) => {
			state.message = '';
			state.error = '';
			state.loading = true;
		},
		[getUserDetail.fulfilled]: (state) => {
			state.message = 'User detail fetched successfully';
			state.error = '';
			state.loading = false;
		},
		[getUserDetail.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[updateUserDetail.pending]: (state) => {
			state.message = '';
			state.error = '';
			state.loading = true;
		},
		[updateUserDetail.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'User updated successfully';
		},
		[updateUserDetail.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[deleteMultipleUser.pending]: (state) => {
			state.userLoader = true;
			state.message = '';
			state.error = '';
			state.loading = true;
		},
		[deleteMultipleUser.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'Multiple users deleted successfully';
		},
		[deleteMultipleUser.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[deleteUser.pending]: (state) => {
			state.message = '';
			state.error = '';
			state.loading = true;
		},
		[deleteUser.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'User deleted successfully';
		},
		[deleteUser.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
	},
});
// eslint-disable-next-line no-empty-pattern
export const {} =  userSlice.actions;

export default userSlice.reducer;
