import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Downshift from 'downshift';


export default function TagsInput({ ...props }) {
	// const classes = useStyles();
	const { selectedTags, placeholder, tags, ...other } = props;
	const [inputValue, setInputValue] = React.useState('');
	const [selectedItem, setSelectedItem] = React.useState([]);
	useEffect(() => {
		setSelectedItem(tags);
	}, [tags]);

	function handleKeyDown(event) {
		if (event.key === 'Enter') {
			const newSelectedItem = [...selectedItem];
			const duplicatedValues = newSelectedItem.indexOf(
				event.target.value.trim()
			);

			if (duplicatedValues !== -1) {
				setInputValue('');
				return;
			}
			if (!event.target.value.replace(/\s/g, '').length) return;

			newSelectedItem.push(event.target.value.trim());
			setSelectedItem(newSelectedItem);
			selectedTags(newSelectedItem);
			setInputValue('');
		}
		if (
			selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
		) {
			const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1);
			setSelectedItem(newSelectedItem);
			selectedTags(newSelectedItem);
		}
		if (event.key === 'Tab') {
			const value = event.target.value.trim();
			let newSelectedItem = [...selectedItem];
			if (value) {
				if (newSelectedItem.indexOf(value) === -1) {
					newSelectedItem = [...newSelectedItem, value];
				}
			}
			setInputValue('');
			selectedTags(newSelectedItem);
			selectedTags(newSelectedItem);
		}
	}
	function handleChange(item) {
		let newSelectedItem = [...selectedItem];
		if (newSelectedItem.indexOf(item) === -1) {
			newSelectedItem = [...newSelectedItem, item];
		}
		setInputValue('');
		selectedTags(newSelectedItem);
		setSelectedItem(newSelectedItem);
	}

	const handleDelete = item => () => {
		const newSelectedItem = [...selectedItem];
		newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
		selectedTags(newSelectedItem);
		setSelectedItem(newSelectedItem);
	};

	function handleInputChange(event) {
		setInputValue(event.target.value);
	}
	const onOuterClick = ({ inputValue }) => {
		const value = inputValue.trim();
		let newSelectedItem = [...selectedItem];
		if (value && newSelectedItem.indexOf(value) === -1) {
			newSelectedItem = [...newSelectedItem, value];
		}
		setInputValue('');
		selectedTags(newSelectedItem);
		selectedTags(newSelectedItem);
	};

	return (
		<React.Fragment>
			<Downshift
				id="downshift-multiple"
				inputValue={inputValue}
				onChange={handleChange}
				onOuterClick={onOuterClick}
				selectedItem={selectedItem}
			>
				{({ getInputProps }) => {
					const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
						onKeyDown: handleKeyDown,
						placeholder
					});
					return (
						<div>
							<TextField
								InputProps={{
									startAdornment: selectedItem.map(item => (
										<Chip
											key={item}
											tabIndex={-1}
											label={item}
											className="tag-input-chip"
											onDelete={handleDelete(item)}
										/>
									)),
									onBlur,
									onChange: event => {
										handleInputChange(event);
										onChange(event);
									},
									onFocus
								}}
								{...other}
								{...inputProps}
							/>
						</div>
					);
				}}
			</Downshift>
		</React.Fragment>
	);
}
TagsInput.defaultProps = {
	tags: []
};
TagsInput.propTypes = {
	selectedTags: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string)
};
