import firebase from '../../utils/firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut, createUserWithEmailAndPassword } from 'firebase/auth';

export function login(email, password) {
	return signInWithEmailAndPassword(firebase.auth, email, password);
}

export function forgotPassword(email) {
	return sendPasswordResetEmail(firebase.auth, email);
}

export function Logout() {
	return signOut(firebase.auth);
}

export async function createUser(data) {
	return await createUserWithEmailAndPassword(firebase.auth, data.email, data.password);
}