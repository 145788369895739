import { styled } from '@mui/material/styles';
import { futura, futuraLight, lightBackgorund, disabledColor, black, white, gray600, gray900 } from '../variables';

export const StyledCard = styled('div')(
	() => `
		width: 25%;
		.card-main {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 24px;
			width: 92%;
			background: ${lightBackgorund};
			border: 2px solid ${disabledColor};
			margin-bottom: 40px;
			cursor: pointer;
			margin: 0 auto 30px;
			&.dark {
				background: ${black};
				border: 2px solid ${black};
			}
			&.main-group-card {
				.card-info {
					.card-info-item {
						width: 50%;
						&.location-item {
							width: 50%;
						}
					}
				}
			}
			.card-logo {
				overflow: hidden;
				height: 163px;
				width: 100%;
				text-align: center;
				background: #C4C4C4;
				img {
					width: 100%;
				}
			}
			.card-title {
				height: 27px;	
				font-family: ${futura};
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 27px;
				text-transform: capitalize;	
				color: ${black};
				margin-bottom: 10px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.card-des {
				height: 38px;
				font-family: Futura LT Pro-light;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 18px;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #000;
				margin-top: 0px;
				margin-bottom: 24px;
				text-transform: capitalize;
				width: 100%;
			}
			.card-info {
				display: flex;
				margin-bottom: 24px;
				width: 100%;
				.card-info-item {
					width: 40%;
					display: flex;
					align-items: center;
					font-family: ${futuraLight};
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 18px;
					color: ${gray600};
					margin-right: 10px;	
					text-transform: capitalize;
					&:last-child {
						margin-right: 0;
					}
					&.location-item {
						width: 20%;
					}
					img {
						margin-right: 5px;
					}
					p {
						margin: 0px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 100%;
					}
				}
			}
			.card-button {
				border: 1px solid ${black};
				border-radius: 0px;
				width: 100%;
				padding: 0;
				height: 57.68px;
				a {
					display: block;
					width: 100%;
					height: 100%;
					line-height: 3em;
					font-family: ${futura};
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					color: ${gray900};
					text-decoration: none;
					text-transform: capitalize;
					background-color: ${white};
					&:hover {
						background-color: ${lightBackgorund};
						color: ${gray600}
					}
				}
			}
		}
		@media only screen and (max-width: 1630px) {
			.card-main {
				width: 90%;
			}
        }
		@media only screen and (max-width: 1560px) {
            width: 33.3%;
			.card-main {
				width: 92.5%;
			}
        }
		@media only screen and (max-width: 1368px) {
			.card-main {
				width: 91%;
			}
        }
		@media only screen and (max-width: 1200px) {
            width: 50%;
			.card-main {
				width: 92%;
			}
        }
		@media only screen and (max-width: 991px) {
			.card-main {
				width: 94%;
			}
        }
		@media only screen and (max-width: 800px) {
			.card-main {
				width: 93.5%;
			}
        }
		@media only screen and (max-width: 767px) {
            width: 65%;
			.card-main {
				margin: 0 auto 20px;
			}
        }
		@media only screen and (max-width: 520px) {
            width: 100%;
			margin-right: 0px;
			margin-left: 0px;
			.card-main {
				width: 100%;
			}
        }
	`,
);
