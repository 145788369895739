import { styled } from '@mui/material/styles';
import { disabledColor, highEmphasis, optima, futura, black, dividerColor, futuraLight, lightBackgorund, white } from '../variables';

export const NewUserStyled = styled('div')(
	() => `
		.page-header {
			display: flex;	
			justify-content: start;
			align-items: center;
			height: 91px;
            border-bottom: 2px solid ${disabledColor};
            margin: 0 -40px;
            padding: 0 40px;
            position: fixed;
            top: 0;
            width: 100%;
            background-color: ${white};
            z-index: 2;
            .page-title {
                font-family: ${optima};
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 48px;
                letter-spacing: 0.25px;
                color: ${highEmphasis};
            }
            a {
                color: ${highEmphasis}; 
                margin-right: 15px;
                &:hover {
                    color: ${disabledColor};
                    background-color: transparent;
                }
            }
		}
        .page-content {
            margin-top: 102px;
            .content-title {
                font-family: ${futura};
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: ${black};
                padding: 16.5px 0 16.5px 15px;
                margin: 10px 0 25px 0;
                border-bottom: 2px solid ${dividerColor};
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    border-bottom: 2px solid ${black};
                    width: 160px;                
                }
            }
            .field-input-text {
                font-family: ${futuraLight};
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                color: ${highEmphasis};
                margin-bottom: 0px;
                margin-top: 0;
            }
            .field-input {
                height: 40px;
                border: 1px solid ${disabledColor};
                color: ${highEmphasis};
                border-radius: 0;
                width: 100%;
                padding-left: 15px;
                font-family: ${futuraLight};
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 16px;
                input {
                    padding-left: 0px; 
                }
                &:focus-visible {
                    outline-style: none;
                    border-color: ${black};
                }
                &:disabled {
                    opacity: .6;
                }
            }
            textarea {
                &.field-input {
                    height: auto;
                    min-height: 40px;
                    padding-top: 15px;
                }
            }
            .time-zone-select {
                max-width: 680px;
                width: 100%;
                .field-input {
                    width: 100%;
                    max-width: 100%;
                    border: white;
                    height: 45px;
                    padding-left: 0px;
                    .MuiSelect-select {
                        padding-left: 15px;
                    }
                }
                .Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: ${highEmphasis};
                        border-width: 1px;
                        border-radius: 0;
                    }
                }
            }
            .create-btn {
                display: block;
                border: 1px solid ${black};
                border-radius: 0;
                max-width: 680px;
                width: 100%;
                max-height: 48px;
                height: 100%;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                margin-bottom: 80px;
                text-transform: none;
                margin-top: 20px;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }
            .from-div {
                max-width: 660px;
            }
            .upload-btn {
                display: block;
                width: 100%;
                border: 1px solid ${black};
                border-radius: 0;
                min-height: 48px;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                text-transform: none;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }
            .pass-input {
                max-width: 680px;
                width: 100%;
                .field-input {
                    height: 45px;
                    border: none;
                    &.Mui-disabled {
                        background: ${lightBackgorund};
                        opacity: .6;
                    }
                }
                .Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: ${highEmphasis};
                        border-width: 1px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1200px) { 
            .page-content {
                .content-title {
                    margin-right: 10px;
                }
                .MuiGrid-grid-md-6 {
                    padding-right: 10px;
                }
            }
		}
        @media only screen and (max-width: 991px) { 
            .page-header {
                position: relative;
                z-index: 0;
                margin: 0 -18px;
                padding: 0 18px;
            }
            .page-content {
                margin-top: 0;
                .from-div {
                    margin-right: 20px;
                }
                .create-btn {
                    margin-bottom: 40px;
                }
                .MuiGrid-grid-md-6 {
                    margin-bottom: 10px;
                }
            }
		}
        @media only screen and (max-width: 355px) {
            .page-header {
                .page-title {
                    font-size: 26px;
                } 
            }
           .page-content {
                .content-title {
                    font-size: 18px;
                }
           }
        }
	`,
);
