import { styled } from '@mui/material/styles';
import { disabledColor, highEmphasis, optima, futura, black, dividerColor, futuraLight, lightBackgorund, white, UserHeading, lightBorderInnerColor, mediumEmphasis, highEmphasisHover } from '../variables';

const NewStationStyled = styled('div')(
	() => `
        .pos-relative {
            position: relative;
            .error-msg-div {
                position: absolute;
                left: 15px;
                margin-top: 8px;
            }
        }
		.page-header {
			display: flex;	
			justify-content: start;
			align-items: center;
			height: 91px;
            border-bottom: 2px solid ${disabledColor};
            margin: 0 -40px;
            padding: 0 40px;
            position: fixed;
            top: 0;
            width: 100%;
            background-color: ${white};
            z-index: 2;
            a {
                color: ${highEmphasis}; 
                margin-right: 15px;
                &:hover {
                    color: ${disabledColor};
                    background-color: transparent;
                }
            }
            .page-title {
                font-family: ${optima};
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 48px;
                letter-spacing: 0.25px;
                color: ${highEmphasis};
                width: 50%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            @media only screen and (max-width: 1200px) { 
                .page-title {
                    width: 40%;
                }
            }
            @media only screen and (max-width: 1200px) { 
                
                &.delete-header {
                    .page-title {
                        width: 50%;
                    }
                }
                .page-title {
                    width: 100%;
                }
            }
            @media only screen and (max-width: 575px) { 
                &.delete-header {
                    align-items: baseline;
                    height: 150px;
                    .page-title {
                        width: 100%;
                    }
                }
            }
		}
        .page-content {
            .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                width: 2.3rem;
                line-height: 2.3rem;
            }
            .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected,
            .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
                background-color: ${black};
                border-radius: 0.2rem;
            }
            .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
                margin-bottom: 2px;
                font-size: 1rem;
            }
            .react-datepicker__header {
                background-color: ${black};
                padding: 12px 0 8px;
            }
            .react-datepicker__day-name, .react-datepicker__current-month {
                color: ${white};
            }
            .react-datepicker__day--selected {
                background-color: ${black};
            }
            .react-datepicker__year-read-view--down-arrow, 
            .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, 
            .react-datepicker__navigation-icon::before {
                border-color: ${white};
            }
            .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
            .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
                border-bottom-color: ${black};
            }
            .react-datepicker {
                box-shadow: 0px 1px 7px 0px #cfcfcf;
            }
            .right-grid {
                padding-left: 40px;
            }
            .MuiAutocomplete-root .MuiOutlinedInput-root {
                padding: 3px;
                border-radius: 0;
            }
            .MuiAutocomplete-popper div {
                background-color: red; 
                box-shadow: ;
            }
            .MuiGrid-grid-xs-12 .MuiAutocomplete-popper div li:hover {
                background-color: green;
            }
            .delete-btn {
                max-width: 214px;
                max-height: 48px;
                height: 100%;
                width: 100%;
                border: 1px solid ${highEmphasis};
                border-radius: 0px;
                padding: 0;
                margin-right: 16px;
                background-color: ${highEmphasis};
                top: 20px;
                z-index: 4;
                &:hover {
                    background-color: ${highEmphasisHover};
                } 
                position: absolute;
                right: 52px;
                font-family: ${futura};
                font-size: 20px;
                text-decoration: none;
                text-transform: none;
                line-height: 45px;
                letter-spacing: 0.1em;
                color: ${white};
                    
                @media only screen and (max-width: 991px) { 
                    z-index: 0;
                    top: 111px;
                }
                @media only screen and (max-width: 575px) { 
                    top: 173px;
                    left: 20px;
                    max-width: 180px;
                    max-height: 44px;
                    font-size: 18px;
                }
                @media only screen and (max-width: 355px) { 
                   a {
                        font-size: 18px;
                   } 
                }
            }
            
            margin-top: 102px;
            .content-title {
                font-family: ${futura};
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: ${black};
                padding: 16.5px 0 16.5px 15px;
                margin: 0px 0 25px 0;
                border-bottom: 2px solid ${dividerColor};
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    border-bottom: 2px solid ${black};
                    width: 160px;                
                }
            }
            .new-station-button {
                display: block;
                border: 1px solid ${black};
                border-radius: 0;
                max-width: 680px;
                width: 100%;
                max-height: 48px;
                height: 100%;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                margin-bottom: 80px;
                margin-top: 24px;
                text-transform: none;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }
            .field-input-text {
                font-family: ${futuraLight};
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                color: ${highEmphasis};
                margin-bottom: 0px;
                margin-top: 0;
            }
            .field-input {
                height: 40px;
                border: 1px solid ${disabledColor};
                color: ${highEmphasis};
                border-radius: 0;
                width: 100%;
                padding-left: 15px;
                font-family: ${futuraLight};
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 16px;
                box-sizing: border-box;
                &:focus-visible {
                    outline-style: none;
                    border-color: ${black};
                }
            }
            textarea.field-input {
                height: auto;
                min-height: 45px;
                padding-top: 15px;
                resize: vertical;
            }
            .btn-container {
                margin-top: 0;
                margin-left: 0;
            }
            .tag-input {
                margin-bottom: 16px;
                .MuiOutlinedInput-root {
                    border-radius: 0;
                    flex-wrap: wrap;
                    #downshift-multiple-input {
                        padding: 10px 0;
                        color: ${highEmphasis};
                        font-family: ${futuraLight};
                        width: auto;
                    }
                    &.Mui-focused {
                        .MuiOutlinedInput-notchedOutline {
                            border-color: ${highEmphasis};
                            border-width: 1px;
                        }
                    }
                    .MuiOutlinedInput-notchedOutline {
                        border-color: ${disabledColor};
                    }
                }
                .tag-input-chip {
                    color: ${highEmphasis};
                    font-family: ${futuraLight};
                    margin: 10px 5px 10px 5px;
                }
            }
            .time-zone-select {
                max-width: 680px;
                width: 100%;
                .field-input {
                    width: 100%;
                    max-width: 100%;
                    border: white;
                    height: 45px;
                    padding-left: 0;
                    color: ${highEmphasis};
                    input {
                        font-family: ${futuraLight};
                    }
                    .MuiSelect-select {
                        padding-left: 15px;
                    }
                }
                .Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: ${highEmphasis};
                        border-width: 1px;
                        border-radius: 0;
                    }
                }
            }
            .from-div {
                max-width: 680px;
                &.date {
                    max-width: 680px;
                    .MuiFormControl-root {
                        width: 100%;
                        margin-bottom: 16px;
                        .MuiOutlinedInput-root {
                            &.Mui-error {
                                .MuiOutlinedInput-notchedOutline {
                                    border-color: ${dividerColor};
                                }
                            }
                        }
                        .MuiOutlinedInput-notchedOutline {
                            border-radius: 0;    
                        }
                        .MuiOutlinedInput-root {
                            height: 45px;
                            .MuiInputBase-input {
                                font-family: ${futuraLight}
                            }
                        }
                        .Mui-focused {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: ${highEmphasis};
                                border-width: 1px;
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
            .upload-btn {
                display: block;
                width: 100%;
                border: 1px solid ${black};
                border-radius: 0;
                min-height: 48px;
                font-family: ${futura};
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: ${highEmphasis};
                text-transform: none;
                max-width: 486px;
                margin-left: auto;
                &:hover  {
                    background-color: ${lightBackgorund};
                }
            }
            .logo-div {
                overflow: hidden;
                max-width: 486px;
                text-align: center;
                max-height: 251px;
                margin-bottom: 15px;
                margin-top: 20px;
                margin-left: auto;
                background: #C4C4C4;
                img {
                    width: 100%;
                }
            }
            .img-des {
                margin-top: 35px;
                font-family: ${futuraLight};
                font-size: 14px;
                line-height: 2.5em;
                letter-spacing: 0.1em;
                color: ${black};
                margin-bottom: 30px;
                max-width: 486px;
                margin-left: auto;
            }
        }  
        @media only screen and (max-width: 1535px) { 
            .page-content {
                .content-title {
                    margin-right: 0px;
                }
                .MuiGrid-grid-md-6 {
                    padding-right: 10px;
                }
                .right-grid {
                    padding-left: 16px;
                }
            }
		}      
        @media only screen and (min-width: 900px) { 
            .page-content {
                .content-title {
                    margin-right: 10px;
                }
                .MuiGrid-grid-md-6 {
                    padding-right: 10px;
                }
            }
		}
        @media only screen and (max-width: 991px) { 
            .page-header {
                position: relative;
                z-index: 0;
                margin: 0 -18px;
                padding: 0 18px;
            }
           
            .page-content {
                margin-top: 0;
                .new-station-button {
                    margin-bottom: 40px;
                }
                .MuiGrid-grid-md-6 {
                    margin-bottom: 10px;
                }
                .logo-div {
                    margin-left: 0;
                }
                .upload-btn {
                    margin-left: 0;
                }
                .img-des {
                    margin-left: 0;
                }
            }
		}
        @media only screen and (max-width: 767px) {  
            .page-content {
            }
        }
        @media only screen and (max-width: 355px) { 
            .page-header {
                .page-title {
                    font-size: 26px;
                }
            }
            .page-content {
                .content-title {
                    font-size: 18px;
                }
            }
        }
	`,
);

export const InfoSection = styled('div')(
	() => `
            width: 100%;
            max-width: 817px;
            overflow-x: auto;
            margin-bottom: 20px;
    `,
);
export const InfoContainer = styled('div')(
	() => ` 
            @media only screen and (max-width: 1535px) { 
                margin-bottom: 20px;  
            } 
            @media only screen and (max-width: 767px) { 
                min-width: 730px;
            }
    `,
);
export const InfoBox = styled('div')(
	() => `
            height: 324px;
            border: 1px solid ${lightBorderInnerColor};
            padding: 10px 9px;
            overflow-y: auto;
            font-family: ${futuraLight};
    `,
);
export const InfoList = styled('div')(
	() => `
        padding: 0 9px; 
    `,
);
export const InfoListItem = styled('div')(
	() => `
        height: 42px;
        border-bottom: 1px solid ${lightBorderInnerColor};
        text-align: center;
        line-height: 2.5em;
        font-family: ${futuraLight};
        color=${mediumEmphasis}
    `,
);
export const InfoTitle = styled('div')(
	() => `
            font-family: ${futura};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;                        
            color: ${UserHeading};
            margin-top: 44px;
            margin-left: 20px;
            margin-bottom: 20px;
            @media only screen and (max-width: 1535px) { 
                margin-top: 20px;
            }
    `,
);

export const HeaderList = styled('div')(
	() => `
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;
    `,
);

export const HeaderListInnerDiv = styled('div')(
	() => `
            width: 50%;
            text-align: center;
            font-family: ${futuraLight};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
    `,
);

export const HeaderListInnerDivs = styled('div')(
	() => `
            width: 50%;
            text-align: center;
            font-family: ${futuraLight};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin-right: 35px;
    `,
);

export default NewStationStyled;
