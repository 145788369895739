import React from 'react';
import CryptoJS from 'crypto-js';
import {
	Grid,
} from '@mui/material';
import { InfoSection, InfoContainer, InfoBox, InfoList, InfoListItem, InfoTitle, HeaderList, HeaderListInnerDiv, HeaderListInnerDivs } from '../../styles/pages/newStation';

const UserDetail = ({ users }) => {
	return (
		<>
			<InfoSection>
				<InfoTitle>	Users </InfoTitle>
				<InfoContainer>
					<HeaderList>
						<HeaderListInnerDiv>UserName</HeaderListInnerDiv>
						<HeaderListInnerDivs>Email address</HeaderListInnerDivs>
						<HeaderListInnerDiv>Password</HeaderListInnerDiv>
					</HeaderList>
					<InfoBox>
						{
							users.length ?
								users.map((user, index) => {
									const getPassword = user && user.password && user.password;
									const decryptPassword  = CryptoJS.AES.decrypt(getPassword, '123456').toString(CryptoJS.enc.Utf8);
									return (
										<Grid key={index} container spacing={2}>
											<Grid item xs={4}>
												<InfoList>
													<InfoListItem>
														{user &&user.username && user.username}
													</InfoListItem>
												</InfoList>
											</Grid>
											<Grid item xs={4}>
												<InfoList>
													<InfoListItem>
														{user &&user.email && user.email}
													</InfoListItem>
												</InfoList>
											</Grid>
											<Grid item xs={4}>
												<InfoList>
													<InfoListItem>
														{decryptPassword}
													</InfoListItem>
												</InfoList>
											</Grid>

										</Grid>
									);
								})
								: 'No users found'
						}
					</InfoBox>
				</InfoContainer>
			</InfoSection>
		</>
	);
};

export default UserDetail;