import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorHandle } from '../../utils/utils';
import * as groupService from '../service';

const initialState = {
	loading: false,
	groupsLoader: false,
	groupImageLoader: false,
	error: 'invaild argumentsdfsd',
	message: '',
	GroupsList: []
};


export const addNewGroup = createAsyncThunk(
	'group/addNewGroup',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail, groupImage, selectedStation, navigate } = data;
			const groupDetail = {
				...detail,
				createdDate: new Date().getTime(),
				isDeleted: false,
			};
			delete groupDetail.groupImage;
			const response = await groupService.addGroup('groups', groupDetail);
			if (!response) {
				return rejectWithValue();
			}
			groupDetail.id = response.id;
			if (groupImage) {
				const imageUrl = await groupService.uploadGroupImage(groupImage, response.id);
				if (!imageUrl) {
					return rejectWithValue(imageUrl);
				}
				groupDetail.groupImage = imageUrl;
				await groupService.updateGroupDetail('groups', groupDetail);
			}
			if (selectedStation.length > 0) {
				for (let i=0; i < selectedStation.length; i++ ) {
					const stationdetail = {
						...selectedStation[i],
						groupId: response.id,
					};
					await groupService.updateStation('stations', stationdetail);
				}
			}
			return fulfillWithValue({ ...data, id: response.id }), navigate();
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);
export const getGroupsList = createAsyncThunk(
	'group/getGroupsList',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const GroupsList = await groupService.getGroups('groups');
			if (!GroupsList) {
				return rejectWithValue();
			}
			return fulfillWithValue(GroupsList);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const getGroupDetail = createAsyncThunk(
	'group/getGroupDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await groupService.getGroupDetails('groups', data.id);
			const stationList = await groupService.getStation('stations');
			return data.fetchDetail({ detail: response, stationList }), fulfillWithValue(response);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const updateGroupDetail = createAsyncThunk(
	'group/updateGroupDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail, selectedStation, navigate } = data;
			if (detail.oldImageUrl) {
				const imageUrl = await groupService.uploadGroupImage(detail.groupImage, detail.id);
				if (!imageUrl) {
					return rejectWithValue(imageUrl);
				}
				detail.groupImage = imageUrl;
			} else if (detail.groupImage.includes('base64')) {
				const imageUrl = await groupService.uploadGroupImage(detail.groupImage, detail.id);
				if (!imageUrl) {
					return rejectWithValue(imageUrl);
				}
				detail.groupImage = imageUrl;
			}
			delete detail.oldImageUrl;
			if (selectedStation.length) {
				for (let i = 0; i < selectedStation.length; i++) {
					const stationDetail = selectedStation[i];
					if (!stationDetail.checked) {
						delete stationDetail.groupId;
					}
					stationDetail.checked;
					delete stationDetail.disabled;
					await groupService.updateStation('stations', stationDetail);
				}
			}
			const response = await groupService.updateGroupDetail('groups', detail);
			if (!response) {
				return navigate(), fulfillWithValue();
			}
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const deleteGroupDetail = createAsyncThunk(
	'group/deleteGroupDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail, navigate } = data;
			if (detail.selectStation.length) {
				for (let i = 0; i < detail.selectStation.length; i++) {
					const station = detail.selectStation[i];
					delete station.groupId;
					delete station.checked;
					delete station.disabled;
					await groupService.updateStation('stations', station);
				}
			}
			const response = await groupService.deleteGroup('groups', detail);
			if (!response) {
				return navigate(), fulfillWithValue();
			}
		} catch (error) {
			rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {
		ClearError: (state) => {
			state.error =  '';
			state.message = '';
			state.loading = false;
			state.groupImageLoader = false;
			state.GroupsList = [];
		},
	},
	extraReducers: {
		[addNewGroup.pending]: (state) => {
			state.groupImageLoader = false;
			state.loading = true;
			state.error = '';
			state.message = '';
		},
		[addNewGroup.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'New group created successfully';
		},
		[addNewGroup.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.message = '';
		},
		[getGroupsList.pending]: (state) => {
			state.loading = true;
			state.groupsLoader = true;
			state.message = '';
			state.error = '';
			state.GroupsList = [];
		},
		[getGroupsList.fulfilled]: (state, action) => {
			state.loading = false;
			state.groupsLoader = false;
			state.message = 'Group Listing data get successfully';
			state.GroupsList = action.payload;
		},
		[getGroupsList.rejected]: (state, action) => {
			state.loading = false;
			state.groupsLoader = false;
			state.GroupsList = [];
			state.message = '';
			state.error = action.payload;
		},
		[getGroupDetail.pending]: (state) => {
			state.loading = true;
			state.message = '';
			state.error = '';
			state.GroupsList = [];
		},
		[getGroupDetail.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'Group Detail get successfully';
		},
		[getGroupDetail.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
		},
		[updateGroupDetail.pending]: (state) => {
			state.loading = true;
			state.message = '';
			state.error = '';
		},
		[updateGroupDetail.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'Group detail updated successfully';
		},
		[updateGroupDetail.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
		},
		[deleteGroupDetail.pending]: (state) => {
			state.loading = true;
			state.message = '';
			state.error = '';
		},
		[deleteGroupDetail.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'Group deleted successfully';
		},
		[deleteGroupDetail.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
		}
	}
});
export const { ClearError } = groupSlice.actions;
export default groupSlice.reducer;