import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { white } from '../../styles/variables';


export default function SimpleBackdrop() {
	return (
		<div>
			<Backdrop
				sx={{ color: `${white}`, zIndex: 999 }}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
