import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorHandle } from '../../utils/utils';
import * as faqService from '../service';

const initialState = {
	loading: false,
	FAQ: {}
};


export const addNewFaq = createAsyncThunk(
	'Faq/addNew',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail } = data;
			for (let i = 0; i < detail.length; i++) {
				const question = detail[i];
				if (question.isDeleted && !question.addNew) {
					await faqService.deleteFaq('Faq', question.id);
				} else if (question.addNew && !question.isDeleted) {
					delete question.id;
					delete question.addNew;
					await faqService.addFAQ('Faq', question);
				} else if (!question.addNew && !question.isDeleted) {
					await faqService.updateFaq('Faq', question);
				}
			}
			return fulfillWithValue(), data.response({ success: true });
		} catch (error) {
			return rejectWithValue({ error: ErrorHandle(error.code) }), data.response({ error: ErrorHandle(error.code) });
		}
	}
);
export const getFAQDetils = createAsyncThunk(
	'Faq/FaqDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const FaqDetail = await faqService.getFAQDetail('Faq');
			if (!FaqDetail) {
				return rejectWithValue(), data('data not found');
			}
			return fulfillWithValue(FaqDetail), data(FaqDetail);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code)), data(ErrorHandle(error.code));
		}
	}
);

export const updateFaqDetails = createAsyncThunk(
	'Faq/updateFaqDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const response = await faqService.updateFaq('Faq', data.detail);
			if (!response) {
				return rejectWithValue(), data.response({ success: true });
			}
			return fulfillWithValue({ ...data, id: response.id }), data.response({ success: true });
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code)), data.response({ error: ErrorHandle(error.code) });
		}
	}
);

export const FaqSlice = createSlice({
	name: 'Faq',
	initialState,
	reducers: {},
	extraReducers: {
	}
});
export default FaqSlice.reducer;