import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewUserStyled } from '../../styles/pages/newUser';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CryptoJS from 'crypto-js';
import { Grid, Select, FormControl, MenuItem, Button, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getStationList } from '../../redux/slice/stationSlice';
import ErrorMsg from '../../components/ErrorMsg';
import {
	getUserDetail,
	addUser,
	updateUserDetail
} from '../../redux/slice/userSlice';
import SimpleBackdrop from '../../components/loader';

const NewUser = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stations = useSelector(state => state.station.stations);
	const [selectedUser, setSelectedUser] = React.useState('');
	const userState = useSelector((state) => state.user);
	let params = useParams();

	const [values, setValues] = React.useState({
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		showPassword: false,
		station: ''
	});
	const [errors, setErrors] = React.useState({});

	useEffect((data) => {
		dispatch(getStationList(data));
	}, []);

	useEffect(() => {
		if (params.id) {
			setSelectedUser(params.id);
			dispatch(getUserDetail({ id: params.id, fetchDetail: fetchUserDetail }));
		} else {
			setSelectedUser();
		}
	}, [params]);

	const fetchUserDetail = (detail) => {
		const selectedStation = stations.find(el => el.id === detail.station.id);
		setValues({ ...detail, repeatpassword: detail.password, station: selectedStation ? selectedStation.id : '' });
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const [revalues, setRevalues] = React.useState({
		showPassword: false,
	});

	const handleChangePass = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleClickShowRePassword = () => {
		setRevalues({
			...revalues,
			showPassword: !revalues.showPassword,
		});
	};

	const handleMouseDownRePassword = (event) => {
		event.preventDefault();
	};

	const navigateUserPage = () => {
		navigate('/users');
	};

	const UserForm = () => {
		if (validate()){
			const stationDetail = stations.find((el) => el.id === values.station);
			const encryptPassword = CryptoJS.AES.encrypt(values.password, '123456').toString();
			if (selectedUser){
				const detail = {
					username: values.username.replace(/ /g, ''),
					email: values.email,
					password: encryptPassword,
					station: {
						id: stationDetail && stationDetail.id,
						stationName: stationDetail && stationDetail.stationName,
						stationImage: stationDetail && stationDetail.stationImage
					},
					id: selectedUser,
					createdDate: values.createdDate ? values.createdDate: new Date().getTime(),
					type: values.type ? values.type : 'user',
					isDeleted: false,
					uid: values.uid ? values.uid : ''
				};
				dispatch(updateUserDetail({ detail, navigateUserPage }));
			}
			else {
				const encryptPassword = CryptoJS.AES.encrypt(values.password, '123456').toString();
				const detail = {
					username: values.username,
					email: values.email,
					password: encryptPassword,
					station: {
						id: stationDetail && stationDetail.id,
						stationName: stationDetail && stationDetail.stationName,
						stationImage: stationDetail && stationDetail.stationImage
					},
					createdDate: new Date().getTime(),
					type: 'user',
					isDeleted: false
				};
				dispatch(addUser({ detail, navigateUserPage }));
			}
		}
	};

	const validate = () => {
		let input = values;
		let errors = {};
		let isValid = true;
		if (!input['username']) {
			isValid = false;
			errors['username'] = 'Please enter your User Name.';
		} else if (input['username'].length < 5) {
			isValid = false;
			errors['username'] = 'Minimum 5 characters required.';
		} else if (input['username'].indexOf(' ') >= 0) {
			isValid = false;
			errors['username'] = 'User name in space not allowed.';
		}
		if (!input['email']) {
			isValid = false;
			errors['email'] = 'Please enter your Email Address.';
		} else if (typeof input['email'] !== 'undefined') {

			let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(input['email'])) {
				isValid = false;
				errors['email'] = 'Please enter valid Email Address.';
			}
		}
		if (!input['password']) {
			isValid = false;
			errors['password'] = 'Please enter your Password.';
		} else if (input['password']) {

			let pattern = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/);
			if (input['password'].length < 6) {
				isValid = false;
				errors['password'] = 'Minimum 6 characters required.';
			}
			else if (!selectedUser && !pattern.test(input['password'])) {
				isValid = false;
				errors['password'] = 'Password has at least one number, one character and one special character.';
			}
		}
		if (!input['repeatpassword']) {
			isValid = false;
			errors['repeatpassword'] = 'Please enter your Repeat Password.';
		} else if (input['repeatpassword'] !== input['password']) {
			isValid = false;
			errors['repeatpassword'] = 'Password does not match.';
		}
		if (!input['station']) {
			isValid = false;
			errors['station'] = 'Please enter your Station.';
		}
		setErrors(errors);
		return isValid;
	};

	return (
		<NewUserStyled>
			{userState && userState.loading && <SimpleBackdrop />}
			<div className='page-header'>
				<Link to='/users' className='back-btn'><ArrowBackIosIcon /></Link>
				<h3 className='page-title'>{ selectedUser ? 'Edit User' : 'Add New User'}</h3>
			</div>
			<div className='page-content'>
				<h4 className='content-title'>User Details</h4>
				<Grid container>
					<Grid item xl={8} lg={12} xs={12}>
						<div className="from-div">
							<p className='field-input-text'>User Name</p>
							<input className='field-input' value={values.username} type="text" onChange={handleChange('username')} />
							{errors && errors.username && <ErrorMsg error={errors.username} />}
						</div>
						<div className="from-div">
							<p className='field-input-text'>Email Address</p>
							<input className='field-input' disabled={selectedUser && selectedUser ? true : false} value={values.email} onChange={handleChange('email')} type="text" />
							{errors && errors.email && <ErrorMsg error={errors.email} />}
						</div>
						<>
							<p className='field-input-text'>Password</p>
							<FormControl className='pass-input' variant="outlined">
								<OutlinedInput
									id="outlined-adornment-password"
									type={values.showPassword ? 'text' : 'password'}
									value={values.password}
									onChange={handleChangePass('password')}
									className='field-input'
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{!values.showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
								{errors && errors.password && <ErrorMsg error={errors.password} />}
							</FormControl>
						</>
						<>
							<p className='field-input-text'>Repeat Password</p>
							<FormControl className='pass-input' variant="outlined">
								<OutlinedInput
									id="outlined-adornment-password"
									type={revalues.showPassword ? 'text' : 'password'}
									value={values.repeatpassword}
									onChange={handleChangePass('repeatpassword')}
									className='field-input'
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowRePassword}
												onMouseDown={handleMouseDownRePassword}
												edge="end"
											>
												{!revalues.showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
								{errors && errors.repeatpassword && <ErrorMsg error={errors.repeatpassword} />}
							</FormControl>
						</>

						<p className='field-input-text'>Station</p>
						<FormControl className='time-zone-select'>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={values.station}
								onChange={handleChange('station')}
								className='field-input'
								displayEmpty
								renderValue={
									values.station !== '' ? undefined : () => 'select station'
								}
							>
								{stations && stations.map((el, id) => {
									return (
										<MenuItem key={id} value={el.id}>{el.stationName}</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						{errors && errors.station && <ErrorMsg error={errors.station} />}
					</Grid>
				</Grid>
				{userState.error && <ErrorMsg error={userState.error} />}
				<Button
					disabled={userState.loading}
					className='create-btn'
					onClick={(e) => UserForm(e)}
				>
					{selectedUser ? 'Save' : 'Create new user'}
				</Button>
			</div>
		</NewUserStyled>
	);
};

export default NewUser;
