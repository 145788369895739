import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './routes/index';
import theme from './styles/theme';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import './locales/i18n';

function App() {
	return (
		<>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<Routes />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</>
	);
}

export default App;
