import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#5c5c5c',
		},
		secondary: {
			main: '#132356',
			light: '#FFCA78',
		},
		background: {
			paper: '#F9F9F9',
			default: '#ffffff',
		},
		text: {
			primary: '#59617b',
			secondary: 'rgba(204, 204, 204, 0.75)',
			disabled: 'rgba(0,0,0,0.85)',
		},
		info: {
			main: '#fff7ea',
			light: '#FFDCA6',
		},
		error: {
			main: '#FF0000'
		}
	},
});

export default theme;
