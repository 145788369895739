import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HomeStyled } from '../../styles/pages/home';
import ListingCard from '../../components/listingCard';
import { Button, Select, FormControl, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SimpleBackdrop from '../../components/loader';
import { getStationList } from '../../redux/slice/stationSlice';
import { getGroupsList } from '../../redux/slice/groupSlice';

const Home = () => {

	const dispatch = useDispatch();
	const stationState = useSelector(state => state.station);
	const [filter, setFilter] = React.useState('');

	useEffect(() => {
		dispatch(getStationList());
		dispatch(getGroupsList());
	}, []);

	const handleChange = (event) => {
		setFilter(event.target.value);
	};

	const FilterData = (data) => {

		if (filter === 'Name') {
			const filterData = data.slice().sort((a, b) => b.stationName.toLowerCase() < a.stationName.toLowerCase() ? 1 : -1);
			return filterData;
		}
		else if (filter === 'Date'){
			const filterData = data.slice().sort((a, b) => a.contractExpiredate.seconds - b.contractExpiredate.seconds);
			return filterData;
		}
		else {
			const filterData = data.slice().sort((a, b) => b.createdDate - a.createdDate);
			return filterData;
		}
	};

	return (
		<HomeStyled>
			{stationState.stationsLoader && <SimpleBackdrop />}
			<div className='page-header'>
				<div className="sort">
				Sort By
					<FormControl className='sortBar'>
						<Select
							displayEmpty
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={filter}
							onChange={handleChange}
							renderValue={(value) => value.length ? value : <span>Select</span> }
						>
							<MenuItem value='Date'>Date</MenuItem>
							<MenuItem value='Name'>Name</MenuItem>
						</Select>
					</FormControl>
				</div>
				<Button className='new-station-button'>
					<Link to='/createStation'><AddIcon /> Create new station</Link>
				</Button>
			</div>
			<div className='page-content'>
				<div className="card-list">
					{stationState.stations && stationState.stations.length ?
						FilterData(stationState.stations).map((station, index) => {
							return <ListingCard key={index} station={station} />;
						})
						: !(stationState.stationsLoader) && 'Stations not found'
					}
				</div>
			</div>

		</HomeStyled>
	);
};

export default Home;
