import React, {  useEffect, useState } from 'react';
import { Checkbox, TextField } from '@mui/material';
import {  InfoTitle, PageInfo, InfoList,  InfoListItem } from '../../styles/pages/createGroup';
import StationDetailStyled from '../../styles/components/stationDetail';

const StationDetail = ({ stations, handleUpdate, handleSearch, getDisabledStation }) => {
	const [searchText, setSearchText] = useState('');
	const [allStations, setAllStations] = useState([]);

	const handleChange = (e) => {
		handleSearch(e.target.value);
		setSearchText(e.target.value);
	};

	useEffect(() => {
		setAllStations(stations);
	}, [stations]);

	return (
		<StationDetailStyled>
			<InfoTitle>Associated stations</InfoTitle>
			<TextField placeholder='Search Stations..' variant='outlined' value={searchText} onChange={(e) => handleChange(e)} />
			<PageInfo>
				<InfoList>
					{allStations && allStations.length ?
						allStations.map((station, index) => {
							return (
								<InfoListItem key={index} className={getDisabledStation(station) ?  'disabled': ''} onClick={() => {
									if (!getDisabledStation(station)) {
										handleUpdate(station);
									}
								}}>
									<Checkbox
										disabled={station.disabled ? station.disabled : false}
										checked={station.checked ? station.checked : false}
									/>
									<span className='Station_name'> {station.stationName}</span>
								</InfoListItem>
							);
						})
						: 'Station not found.'
					}
				</InfoList>
			</PageInfo>
		</StationDetailStyled>
	);
};

export default StationDetail;