import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from './protectedRoute';
import Login from '../pages/auth/login';
import Home from '../pages/home';
import Groups from '../pages/groups';
import Users from '../pages/users';
import NewStation from '../pages/create-new-station';
import CreateGroup from '../pages/create-group';
import NewUser from '../pages/new-user';
import NotFound from '../components/notFound';
import ForgotPassword from '../pages/auth/forgotPassword';
import FAQ from '../pages/faq';

const RoutesConfig = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={ <PrivateRoute><Home /></PrivateRoute>} />
				<Route path="/login" element={ <PublicRoute><Login /></PublicRoute>} />
				<Route path="/forgotPassword" element={ <PublicRoute><ForgotPassword /></PublicRoute>} />
				<Route path="/groups" element={<PrivateRoute><Groups /></PrivateRoute>} />
				<Route path="/createStation" element={<PrivateRoute><NewStation /></PrivateRoute>} />
				<Route path="/editSation/:id" element={<PrivateRoute><NewStation /></PrivateRoute>} />
				<Route path="/createUser" element={<PrivateRoute><NewUser /></PrivateRoute>} />
				<Route path="/editUser/:id" element={<PrivateRoute><NewUser /></PrivateRoute>} />
				<Route path="/createGroup" element={<PrivateRoute><CreateGroup /></PrivateRoute>} />
				<Route path="/faq" element={<PrivateRoute><FAQ /></PrivateRoute> } />
				<Route path="/editGroup/:id" element={<PrivateRoute><CreateGroup /></PrivateRoute>} />
				<Route path="/users" element={ <PrivateRoute><Users /></PrivateRoute>} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default RoutesConfig;
