import { styled } from '@mui/material/styles';
import { disabledColor, highEmphasis, optima, futura, black, dividerColor, white, futuraLight } from '../variables';

export const PageHeader = styled('div')(
	() => `
            display: flex;	
            justify-content: start;
            align-items: center;
            height: 91px;
            border-bottom: 2px solid ${disabledColor};
            margin: 0 -40px;
            padding: 0 40px;
            position: fixed;
            top: 0;
            width: 100%;
            background-color: ${white};
            z-index: 2;
            a {
                color: ${highEmphasis}; 
                margin-right: 15px;
               
                &:hover {
                    color: ${disabledColor};
                    background-color: transparent;
                }
            }
            @media only screen and (max-width: 991px) { 
                position: relative;
                z-index: 0;
                margin: 0 -18px;
                padding: 0 18px;
            }
            @media only screen and (max-width: 575px) { 
                &.delete-header {
                    align-items: baseline;
                    height: 150px;
                }
            }
    `,
);

export const PageTitle = styled('h3')(
	() => `
            font-family: ${optima};
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
            letter-spacing: 0.25px;
            color: ${highEmphasis};
            white-space: nowrap;
            overflow-x: hidden;
            width: 65%;
            text-overflow: ellipsis;
            @media only screen and (max-width: 1367px) { 
                margin-top: 24px;
                width: 50%;
            }
            @media only screen and (max-width: 1200px) { 
                margin-top: 24px;
                width: 30%;
            }
            @media only screen and (max-width: 991px) { 
                margin-top: 24px;
                width: 50%;
            }
            @media only screen and (max-width: 575px) { 
                margin-top: 24px;
                width: 100%;
            }
            @media only screen and (max-width: 355px) { 
                font-size: 26px;
            }
    `,
);

export const ContentTitle = styled('h4')(
	() => `
            font-family: ${futura};
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: ${black};
            padding: 16.5px 0 16.5px 15px;
            margin: 10px 0 25px 0;
            border-bottom: 2px solid ${dividerColor};
            @media only screen and (max-width: 355px) { 
                font-size: 18px;
            }
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                border-bottom: 2px solid ${black};
                width: 160px;                
            }
    `,
);

export const FormDiv = styled('div')(
	() => `
            max-width: 680px;
    `,
);

export const FiledInputText = styled('p')(
	() => `
            font-family: ${futuraLight};
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: ${highEmphasis};
            margin-bottom: 5px;
            margin-top: 0;
    `,
);

export const Faqform = styled('form')(
	() => `
            padding-top: 115px;
            @media only screen and (max-width: 991px) { 
                padding-top: 32px;
            }
            .deleteIcon {
                cursor: pointer;
            }
    `,
);
export const FaqList = styled('div')(
	() => `
            .addIcon {
                margin-left: 10px;
            }
    `,
);

export const FieldInputTextArea = styled('textarea')(
	() => `
            min-height: 100px;
            border: 1px solid ${disabledColor};
            color: ${highEmphasis};
            border-radius: 0;
            width: 100%;
            max-width: 100%;
            padding-top: 8px;
            padding-left: 15px;
            font-family: ${futuraLight};
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            box-sizing: border-box;
            &:focus-visible {
                outline-style: none;
                border-color: ${black};
            }
    `
);

export const FieldInput = styled('input')(
	() => `
            height: 40px;
            border: 1px solid ${disabledColor};
            color: ${highEmphasis};
            border-radius: 0;
            width: 100%;
            padding-left: 15px;
            font-family: ${futuraLight};
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            box-sizing: border-box;
            &:focus-visible {
                outline-style: none;
                border-color: ${black};
            }
    `,
);

export const FaqButton = styled('button')(
	() => `
            background: #000;
            margin-top: 10px;
            color: #fff;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: 16px;
            line-height: 1;
            padding: 14px 30px 10px;
            cursor: pointer;
            border: 1px solid;
            &:disabled {
                opacity: 0.3;
            }
    `,
);

export const AddunderLine = styled('div')(
	() => `
    margin-top: 20px;
    border-bottom: 2px solid #aaa;
    margin-bottom: 20px;
`);