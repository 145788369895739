import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupStyled } from '../../styles/pages/groups';
import GroupListingCard from '../../components/Group/groupListCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getGroupsList } from '../../redux/slice/groupSlice';
import SimpleBackdrop from '../../components/loader';

const Groups = () => {
	const dispatch = useDispatch();
	const groupState = useSelector(state => state.groups);

	useEffect(() => {
		dispatch(getGroupsList());
	}, []);

	useEffect(() => {
	}, [groupState]);

	const FilterData = (data) => {
		const filterData = data.slice().sort((a, b) => b.createdDate - a.createdDate);
		return filterData;
	};
	return (
		<GroupStyled>
			{groupState.groupsLoader && <SimpleBackdrop />}
			<div className='page-header'>
				<Button className='new-station-button'>
					<Link to='/createGroup'><AddIcon /> Create new group</Link>
				</Button>
			</div>
			<div className='page-content'>
				<div className="card-list dark">
					{groupState.GroupsList && groupState.GroupsList.length ?
						FilterData(groupState.GroupsList).map((group, index) => {
							return <GroupListingCard key={index} group={group} type='group' />;
						})
						: !(groupState.groupsLoader) && 'Groups Lists not found'
					}
				</div>
			</div>
		</GroupStyled>
	);
};

export default Groups;