import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const ConfirmDelete = ({ open, title, handleOk, setOpen, alert }) => {
	return (
		<div>
			<Dialog open={open} onClose={() => setOpen()}>
				<DialogTitle>
					{title}
				</DialogTitle>
				<DialogActions>
					{!alert ?
						<>
							<Button onClick={() => setOpen()}>Cancel</Button>
							<Button onClick={() => handleOk()} autoFocus>
								OK
							</Button>
						</>:
						<Button onClick={() => setOpen()} autoFocus>
							OK
						</Button>
					}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDelete;
