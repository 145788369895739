import { styled } from '@mui/material/styles';
import { highEmphasis, disabledColor, highEmphasisHover, lightBackgorund, black, optima, futura, futuraLight } from '../variables';

export const LoginCard = styled('div')(
	() => `
		background-color: ${lightBackgorund};
		height: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		.login-container {
			border: 1px solid ${black};
			text-align: center;
			height: 98vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			.logo-div {
				width: 100%;
				padding: 6px 0;
				border-bottom: 0.5px solid ${highEmphasis};
				.logo {
					max-width: 335px;
					width: 90%;
				}
			}
			.content-div {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				.forgot-password {
					font-family: ${futuraLight};
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-decoration: none;
					color: ${highEmphasis};
					text-transform: none;
					padding: 0;
					&:hover {
						background-color: transparent;
						text-decoration: underline;
					}
				}
				.error {
					font-family: ${futuraLight};
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;
				}
				.login-page-title {
					font-style: normal;
					font-weight: 400;
					font-size: 28px;
					line-height: 34px;
					font-family: ${optima};
					margin-bottom: 130px;
				}
				.login-button {
					background: ${highEmphasis};
					border: 1px solid ${highEmphasis};
					color: white;
					border-radius: 0;
					display: block;
					text-align: center;
					margin: 0 auto;
					width: 446px;
					height: 40px;
					margin-bottom: 40px;
					text-transform: none;
					&:hover {
						background-color: ${highEmphasisHover};
					}
					&.disabled {
						background-color: ${disabledColor};
						border: 1px solid ${disabledColor};
					}
				}
				.input-login {
					border-radius: 0;
					display: block;
					margin: 0 auto;
					max-width: 440px;
					width: 100%;
					height: 40px;
					outline: none;
					margin-bottom: 16px;
					box-shadow: none;
					border: 1px solid ${highEmphasis};
					text-align: center;
					font-family: ${futura};
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: ${disabledColor};
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: rgba(0, 0, 0, 0.3);
					}
					&::-moz-placeholder { /* Firefox 19+ */
						color: rgba(0, 0, 0, 0.3);
					}
					&::-ms-input-placeholder { /* IE 10+ */
						color: rgba(0, 0, 0, 0.3);
					}
					&::-moz-placeholder { /* Firefox 18- */
						color: rgba(0, 0, 0, 0.3);
					}
				}
			}
		}
		@media only screen and (max-width: 767px) {
			.login-container {
				.content-div {
					.input-login {
						max-width: 240px;
					}
					.login-button {
						width: 244px;
					}
				}
			}
		}
	`,
);
