import React from 'react';
import { Container } from '@mui/material';
import Sidebar from './sidebar';
import { LayoutBox } from '../../styles/components/layout';

const Layout = ({ children }) => {
	return (
		<LayoutBox>
			<Sidebar />
			<Container className="Layout">
				{children}
			</Container>
		</LayoutBox>
	);
};

export default Layout;