import firebaseapp from '../../utils/firebase';
import { getFirestore, collection, addDoc, getDocs, query, where, doc, setDoc, getDoc } from 'firebase/firestore';

const db = getFirestore(firebaseapp.app);
export async function addUser(name, data) {
	return await addDoc(collection(db, name), data);
}

export async function getUser(name) {
	const docRef = query(collection(db, name), where('isDeleted', '==', false), where('type', '==', 'user'));
	const querySnapshot = await getDocs(docRef);
	const users = [];
	querySnapshot.forEach((doc) => {
		users.push({ id: doc.id, ...doc.data() });
	});
	return users;
}

export async function getUserDetail(name, id) {
	const docRef = doc(db, name, id);
	const querySnapshot = await getDoc(docRef);
	return { ...querySnapshot.data(), id: querySnapshot.id };
}

export async function updateUser(name, data) {
	const db = getFirestore(firebaseapp.app);
	const docRef = doc(db, name, data.id);
	delete data.id;
	return await setDoc(docRef, data);
}

export async function getUsertypeDetail(name, uid) {
	const docRef = query(collection(db, name), where('uid', '==', uid), where('isDeleted', '==', false), where('type', '==', 'admin'));
	const querySnapshot = await getDocs(docRef);
	let userDetail = null;
	querySnapshot.forEach((el) => {
		if (el.data().uid === uid) {
			userDetail = { ...el.data(), id: el.id };
		}
	});
	return userDetail;
}

export async function findExistUserName(name, data) {
	const  docRef = query(collection(db, name), where('username', '==', data.username));
	const querySnapshot = await getDocs(docRef);
	const users = [];
	querySnapshot.forEach((doc) => {
		users.push({ id: doc.id, ...doc.data() });
	});
	return users;
}