import firebaseapp from '../../utils/firebase';
import { getStorage, ref, uploadString, getDownloadURL, deleteObject, list } from 'firebase/storage';
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, query, where, deleteDoc, getDoc } from 'firebase/firestore';

const storage = getStorage();
const db = getFirestore(firebaseapp.app);
// const storageRef = ref(storage, `group/${new Date().getTime() * 1000}.png`);


export async function uploadGroupImage(file, id) {
	const storagePath = ref(storage, `group/${id}/${new Date().getTime() * 1000}.png`);
	await uploadString(storagePath, file, 'data_url');
	return await getDownloadURL(storagePath);
}

export async function addGroup(name, data) {
	return await addDoc(collection(db, name), data);
}

export async function getGroups(name) {
	const docRef = query(collection(db, name), where('isDeleted', '==', false));
	const querySnapshot = await getDocs(docRef);
	const Groups = [];
	querySnapshot.forEach((doc) => {
		Groups.push({ id: doc.id, ...doc.data() });
	});
	return Groups;
}

export async function getGroupDetails(name, id) {
	const docRef = doc(db, name, id);
	const querySnapshot = await getDoc(docRef);
	return { ...querySnapshot.data(), id: querySnapshot.id };
}

export async function updateGroupDetail(name, data) {
	const docRef = doc(db, name, data.id);
	delete data.id;
	return await setDoc(docRef, data);
}

export async function deleteGroup(name, data) {
	if (data.groupImage) {
		await DeleteGroupImages(data.id);
	}
	const docRef = doc(db, name, data.id);
	return await deleteDoc(docRef);
}

export async function deleteImage(imageUrl) {
	const filePath = ref(storage, imageUrl);
	const deleteRef = ref(storage, filePath);
	return await deleteObject(deleteRef);
}


export async function DeleteGroupImages(id) {
	const deleteRef = ref(storage, `group/${id}`);
	const firstPage = await list(deleteRef);
	if (firstPage.items.length) {
		const images = firstPage.items;
		for (let i = 0; i < images.length; i++) {
			const image = images[i];
			await deleteObject(ref(storage, image.fullPath));
		}
		return;
	} return;
}