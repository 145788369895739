import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorHandle } from '../../utils/utils';
import * as stationService  from '../service';

const initialState = {
	loading: false,
	stationsLoader: false,
	stations: [],
	error: '',
	message: '',
};

export const addStation = createAsyncThunk(
	'station/addStation',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail, stationImage } = data;
			delete detail.stationImage;
			const response = await stationService.addStation('stations', detail);
			if (!response) {
				return rejectWithValue();
			}
			if (stationImage) {
				const imageUrl = await stationService.uploadImage(stationImage, response.id);
				if (imageUrl) {
					detail.stationImage = imageUrl;
				}
			}
			detail.id = response.id;
			await stationService.updateStation('stations', detail);
			return data.navigateHomePage(), fulfillWithValue({ ...data, id: response.id });
		} catch (error) {
			return rejectWithValue(ErrorHandle(error));
		}
	}
);


export const getStationList = createAsyncThunk(
	'station/getStationList',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const stations = await stationService.getStation('stations');
			return fulfillWithValue(stations);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const getStationDetail = createAsyncThunk(
	'station/getSationDetail',
	async(data, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await stationService.getStation('stations');
			response.forEach(async(el) => {
				if (el.id === data.id) {
					const users = await stationService.getStationUserDetail('users', data.id);
					data.fetchDetail({ ...el, id: el.id, users });
				}
			});
			return fulfillWithValue(data);
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const updateStationDetail = createAsyncThunk(
	'station/updateStationDetail',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const detail = data.data;
			if (detail.oldImageUrl) {
				const imageUrl = await stationService.uploadImage(detail.stationImage, detail.id);
				if (!imageUrl) {
					return rejectWithValue(imageUrl);
				}
				detail.stationImage = imageUrl;
			} else if (detail.stationImage.includes('base64')) {
				const imageUrl = await stationService.uploadImage(detail.stationImage, detail.id);
				if (!imageUrl) {
					return rejectWithValue(imageUrl);
				}
				detail.stationImage = imageUrl;
			}

			delete detail.oldImageUrl;
			const response = await stationService.updateStation('stations', detail);
			if (!response) {
				return data.navigateHomePage(), fulfillWithValue();
			}
			return fulfillWithValue();
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const deleteStation = createAsyncThunk(
	'station/deleteStation',
	async(data, { rejectWithValue, fulfillWithValue }) => {
		try {
			const { detail, navigateHomePage } = data;
			if (detail.groupId) {
				const getDetail = await stationService.getGroupDetails('groups', detail.groupId);
				const stations =  getDetail.stations.filter(el => el.id !== detail.id);
				const updateDetail = { ...getDetail, stations };
				await stationService.updateGroupDetail('groups', updateDetail);
			}
			await stationService.deleteStation('stations', detail);
			return navigateHomePage(), fulfillWithValue();
		} catch (error) {
			return rejectWithValue(ErrorHandle(error.code));
		}
	}
);

export const stationSlice = createSlice({
	name: 'station',
	initialState,
	reducers: {
		ClearError: (state) => {
			state.error =  '';
			state.message = '';
			state.loading = false;
			state.stations = [];
		},
	},
	extraReducers: {
		[addStation.pending]: (state) => {
			state.loading = true;
			state.error = '';
			state.message = '';
		},
		[addStation.fulfilled]: (state) => {
			state.loading = false;
			state.message = 'New station created successfully';
		},
		[addStation.rejected]: (state, action) => {
			state.loading = false;
			state.message = '';
			state.error = action.payload;
		},
		[getStationList.pending]: (state) => {
			state.loading = true;
			state.stationsLoader = true;
			state.error = '';
			state.message = '';
			state.stations = [];
		},
		[getStationList.fulfilled]: (state, action) => {
			state.loading = false;
			state.stationsLoader = false;
			state.stations = action.payload;
		},
		[getStationList.rejected]: (state, action) => {
			state.loading = false;
			state.stationsLoader = false;
			state.error = action.payload;
		},
		[getStationDetail.pending]: (state) => {
			state.error = '';
			state.message = '';
			state.loading = true;
		},
		[getStationDetail.fulfilled]: (state) => {
			state.error = '';
			state.message = 'Station detail get successfully';
			state.loading = false;
		},
		[getStationDetail.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[updateStationDetail.pending]: (state) => {
			state.error = '';
			state.message = '';
			state.loading = true;
		},
		[updateStationDetail.fulfilled]: (state) => {
			state.error = '';
			state.message = 'Station updated successfully';
			state.loading = false;
		},
		[updateStationDetail.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[deleteStation.pending]: (state) => {
			state.loading = true;
			state.message = '';
		},
		[deleteStation.fulfilled]: (state) => {
			state.loading = false;
			state.error = '';
			state.message = 'Station deleted successfully';
		},
		[deleteStation.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.message = '';
		}
	}
});

export const { ClearError } = stationSlice.actions;
export default stationSlice.reducer;