/* eslint-disable no-useless-escape */
export const ErrorHandle = (error) =>  {
	switch (error) {
		case 'auth/wrong-password':
			return 'Wrong password. Please try again.';
		case 'auth/user-not-found':
			return 'user not found';
		case 'auth/invalid-email':
			return 'Please provide a valid email.';
		case 'auth/too-many-requests':
			return 'We have blocked this device due to unusual activity. Try again later.';
		case 'auth/email-already-in-use':
			return 'Email address already exits. use different email.';
		case 'storage/object-not-found':
			return 'Storage in image not found.';
		case 'invalid-argument':
			return 'Please Enter Valid data.';
		default:
			return error;
	}

};

export const isUrlValid = (inputURL) => {
	let res = inputURL.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	if (res == null)
		return false;
	else
		return true;
};