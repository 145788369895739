import React, { useEffect, useState, useRef } from 'react';
import { UserStyled } from '../../styles/pages/user';
import { Grid, Button, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CryptoJS from 'crypto-js';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import stationLogo from '../../assets/images/user-station-logo.png';
import { getUserList, deleteMultipleUser, deleteUser } from '../../redux/slice/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBackdrop from '../../components/loader';
import { getStationList } from '../../redux/slice/stationSlice';
import ConfirmDelete from '../../components/delete-component';

const Users = () => {
	const userState = useSelector(state => state.user);
	const { stations } = useSelector(state => state.station);
	const wrapperRef = useRef(null);
	const [isOpen, setOpen] = useState('');
	const dispatch = useDispatch();
	const [isOpenAlert, setIsOpenAlert] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [allUsers, setAllUser] = useState([]);
	const [selectedUser, setSelectedUser] = useState('');

	useEffect(() => {
		setAllUser(userState.users ? userState.users : []);
	}, [userState.users]);

	function showEdit(user) {
		setOpen(user.id);
	}

	useEffect(() => {
		loadUsers();
		dispatch(getStationList());
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setOpen('');
		}
	};
	const selectAllUser = (e) => {
		setAllSelected(!allSelected);
		const updateUsers = [];
		userState.users.forEach(user => {
			updateUsers.push({ ...user, checked: e.target.checked ? true : false });
		});
		setAllUser(updateUsers);
	};
	const handleChange = (user) => {
		const allSelectedUser = [...allUsers];
		const updateUser = [];
		allSelectedUser.forEach(el => {
			if (el.id === user.id) {
				updateUser.push({ ...el, checked: !user.checked });
			} else {
				updateUser.push({ ...el });
			}
		});
		if (getSelectCount(updateUser) === updateUser.length) {
			setAllSelected(true);
		} else {
			setAllSelected(false);
		}
		setAllUser(updateUser);
	};

	const getSelectCount = (users) => {
		if (users){
			const selectedUser = users.filter(el => el.checked);
			return selectedUser.length;
		}
		return [];
	};
	const handleDelete = () => {
		setIsOpenAlert(true);
	};
	const handleOK = () => {
		if (selectedUser) {
			setIsOpenAlert(!isOpenAlert);
			setSelectedUser(null);
			dispatch(deleteUser({ detail: selectedUser, navigateUserPage: loadUsers() }));
		} else {
			setIsOpenAlert(!isOpenAlert);
			dispatch(deleteMultipleUser({ users: getSelectedUser() }));
		}
	};
	const loadUsers = () => {
		setAllSelected(false);
		dispatch(getUserList());
	};
	const getSelectedUser = () => {
		const selected = allUsers.filter(el => el.checked);
		return selected ? selected : [];
	};

	const handleDeleteUser = (user) => {
		const detail =  {
			...user,
			isDeleted: true,
		};
		delete detail.checked;
		setIsOpenAlert(true);
		setSelectedUser(detail);
	};

	const getStationDetail = (station) => {
		return stations.find(el => el.id === station.id);
	};

	const FilterData = (data) => {
		const filterData = data.slice().sort((a, b) => b.createdDate - a.createdDate);
		return filterData;
	};

	const getAlertTitle = () => {
		let title = '';
		if (selectedUser) {
			title = 'Are you sure you want to delete this user?';
		} else if (getSelectedUser().length > 0) {
			title = 'Are you sure you want to delete a selected user?';
		} else {
			title = 'Please select the users.';
		}
		return title;
	};
	const handleClose = () => {
		if (selectedUser) {
			setSelectedUser(null);
		}
		setIsOpenAlert(!isOpenAlert);
	};

	return (
		<UserStyled>
			{userState && userState.userLoader && <SimpleBackdrop />}
			<div className='page-header'>
				<div className="select-list">
					{getSelectCount(allUsers)} of {allUsers.length} selected
				</div>
				<div className='header-btns'>
					<Button className='delete-user-btn' onClick={() => handleDelete()}>
						Delete
					</Button>
					<Button className='add-user-btn'>
						<Link to='/createUser'><AddIcon /> Add New User</Link>
					</Button>
				</div>
			</div>
			<div className='page-content'>
				<div className="user-list">
					<div className="user-list-item list-header">
						<Checkbox checked={allSelected} onChange={(e) => selectAllUser(e)} />
						<Grid container>
							<Grid item xs={3} className='first-item'>
								Username
							</Grid>
							<Grid item xs={3}>
								Email Address
							</Grid>
							<Grid item xs={3}>
								Password
							</Grid>
							<Grid item xs={3}>
								Station
							</Grid>
						</Grid>
					</div>
					<div className="list-body">
						{userState.users && userState.users.length ? FilterData(allUsers).map((user, index) => {
							const getPassword = user && user.password && user.password;
							const decryptPassword  = CryptoJS.AES.decrypt(getPassword, '123456').toString(CryptoJS.enc.Utf8);
							return (
								<div ref={wrapperRef} className={index % 2 !== 0 ? 'user-list-item' :  'user-list-item dark-bg'} key={index}>
									<Checkbox checked={user.checked ? user.checked : false} onChange={() => handleChange(user)} />
									<Grid container>
										<Grid item xs={3} className='first-item'>
											{user && user.username && user.username}
										</Grid>
										<Grid item xs={3}>
											{user && user.email && user.email}
										</Grid>
										<Grid item xs={3}>
											{decryptPassword}
										</Grid>
										{user.station && getStationDetail(user.station) &&
											<Grid item xs={3} className='last-item'>
												<div className="img-div-user">
													<img src={getStationDetail(user.station) && getStationDetail(user.station).stationImage ? getStationDetail(user.station).stationImage : stationLogo} alt="" />
												</div>
												<div className="img-div-details">
													{getStationDetail(user.station).stationName && getStationDetail(user.station).stationName}
												</div>
											</Grid>
										}
									</Grid>
									<MoreVertIcon className='open-edit' onClick={() => showEdit(user)} />
									<div className={isOpen !== user.id ? 'edit-btn-tooltip' : 'show  edit-btn-tooltip'}> <Link to={`/editUser/${user.id}`} >Edit </Link> <Button onClick={() => handleDeleteUser(user)}>Delete </Button> <div className="arrow-down"></div> </div>
								</div>
							);
						}) : !(userState && userState.userLoader) && <span className='usernotfound'>Users not found</span> }
					</div>
				</div>
			</div>
			<ConfirmDelete
				open={isOpenAlert}
				title={getAlertTitle()}
				handleOk={() => handleOK()}
				setOpen={handleClose}
				alert={getSelectedUser().length || selectedUser ? false: true}
			/>
		</UserStyled>
	);
};

export default Users;