import { styled } from '@mui/material/styles';
import { futuraLight, errorColor } from '../variables';

export const ErrorContent = styled('div')(
	() => `
           display: flex;
           align-items: center;
           margin: 0 0 16px 0 ;
           &.upload-error {
                max-width: 486px;
                margin: 0 0 10px auto;
                @media only screen and (max-width: 991px) { 
                    margin-left: 0;
                }
           }
    `,
);
export const ErrImg = styled('img') (
	() => `
        height: 15px
    `
);

export const ErrorMessage = styled('p')(
	() =>  `
        color: ${errorColor};
        font-family: ${futuraLight};
        font-size: 16px;
        margin: 0 0 0 10px;
        max-width: 486px;
        &.logo-side {
            margin-left: auto;
        }
    `
);