// Colors
export const highEmphasis = '#000000de';
export const mediumEmphasis = '#00000099';
export const disabledColor = '#0000004d';
export const highEmphasisHover = '#000000ab';
export const lightBackgorund = '#F9F9F9';
export const black = '#000';
export const white = '#fff';
export const dividerColor = '#C4C4C4';
export const decor = '#E8E8E8';
export const gray900 = '#0C1116';
export const gray600 = '#4F5B67';
export const customBoxShadowColor = '#141414';
export const errorColor = '#FF0000';
export const sucsessColor = '#34C759';
export const lightBorderColor = '#DADADA';
export const lightBorderInnerColor = '#BDBDBD';
export const UserHeading = '#1E1E1E';
export const CustomEditShadowLight = '#00000024';
export const CustomEditShadowMedium = '#0000001f';
export const CustomEditShadowDark = '#00000033';
export const placeholderTextColor = '#000000';

// Fonts
export const optima = 'Optima';
export const futura = 'Futura LT Pro';
export const futuraLight = 'Futura LT Pro-light';