/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import logo from '../../../assets/images/logo.png';
import close from '../../../assets/images/cross.png';
import { ForgotPasswordCard } from '../../../styles/pages/forgotPassword';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleForgotPassowrd } from '../../../redux/slice/authSlice';
import ErrorMsg from '../../../components/ErrorMsg';

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const state = useSelector((state) => state.auth);
	const [successMsg, setSuccessMsg] =  useState(false);
	const [inputValues, setInputValue] = useState({
		email: '',
	});
	const [emailError, setEmailError] = useState('');
	useEffect(() => {
		if (state.error) {
			setEmailError(state.error);
		} else {
			setEmailError('');
		}
		if (state.forgotMsg) {
			setSuccessMsg(true);
			navigate('/login');
		}
	}, [state]);

	function handleChange(event) {
		const { name, value } = event.target;
		setInputValue({ ...inputValues, [name]: value });
	}
	const resetPassword  = (e) => {
		e.preventDefault();
		let errors = emailError;
		const result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const valid  = result.test(String(inputValues.email).toLowerCase());
		if (!inputValues.email) {
			errors = 'Email is required';
			setEmailError(errors);
		} else if (!valid) {
			errors = 'Please enter a valid email address.';
			setEmailError(errors);
		} else {
			errors = '';
			setEmailError(errors);
			dispatch(handleForgotPassowrd(inputValues.email));
		}
	};

	return  (
		<ForgotPasswordCard>
			<Container component="main" maxWidth="xxl">
				<NavLink to='/login'><img src={close} className="close-icon" alt="" /></NavLink>
				<Box className="forgot-container">
					<Box className="logo-div">
						<img src={logo} className="logo" alt="" />
					</Box>
					<Box className="content-div">
						<h1 className="page-title">Password recovery</h1>
						<p className='lead'>You will receive a notification on registered e-mail address</p>
						<input className="input-login" placeholder="Email" type="email" name="email" onChange={(e) => handleChange(e)} value={inputValues.email} />
						{emailError ?  <ErrorMsg error={emailError} /> : null }
						<Button disabled={state.loading} className="forgot-button" variant="contained" onClick={(e) => resetPassword(e)}>
							{state.loading ? 'Loading...' : 'Recover password'}
						</Button>
						{successMsg && <p className='success-msg'>{state.message}</p> }
					</Box>
				</Box>
			</Container>
		</ForgotPasswordCard>
	);
};

export default ForgotPassword;
