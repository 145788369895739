import { styled } from '@mui/material/styles';

export const LayoutBox = styled('div')(
	() => `
		display: flex;
        overflow-y: hidden;
        .Layout {
            max-width: 100%;
            max-height: 100vh;
            overflow-y: scroll;
            padding: 0 40px;
        }
        @media only screen and (max-width: 991px) {
            flex-direction: column;
            .Layout {
                padding: 0 18px;
                overflow-y: visible;
                max-height: inherit;
            }
        }
	`,
);
