import { styled } from '@mui/material/styles';
import { highEmphasis, futura, lightBackgorund, black, highEmphasisHover, futuraLight, mediumEmphasis, decor, white, dividerColor, customBoxShadowColor, CustomEditShadowMedium, CustomEditShadowDark, CustomEditShadowLight } from '../variables';

export const UserStyled = styled('div')(
	() => `
		.page-header {
			display: flex;	
			justify-content: space-between;
			align-items: center;
			height: 91px;
			.select-list {
				font-family: ${futuraLight};
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 28px;
				color: ${mediumEmphasis};
				margin-top: 41px;
			}
			.header-btns {
				display: flex;
				width: 50%;
				justify-content: flex-end;
				.add-user-btn {
					max-width: 260px;
					max-height: 48px;
					height: 100%;
					width: 100%;
					border: 1px solid ${black};
					border-radius: 0px;
					padding: 0;
					background-color: ${black};
					&:hover {
						background-color: ${highEmphasisHover};
						border-color: ${highEmphasisHover};
					} 
					a {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-family: ${futura};
						font-size: 20px;
						text-decoration: none;
						text-transform: none;
						line-height: 45px;
						letter-spacing: 0.1em;
						color: ${lightBackgorund};
						height: 100%;
						.MuiSvgIcon-root {
							margin-right: 5px;
						}
					}
				}
				.delete-user-btn {
					max-width: 142px;
					max-height: 48px;
					height: 100%;
					width: 100%;
					border: 1px solid ${highEmphasis};
					border-radius: 0px;
					padding: 0;
					margin-right: 16px;
					&:hover {
						background-color: ${lightBackgorund};
					} 
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: ${futura};
					font-size: 20px;
					text-decoration: none;
					text-transform: none;
					line-height: 45px;
					letter-spacing: 0.1em;
					color: ${highEmphasis};
					.MuiSvgIcon-root {
						margin-right: 5px;
					}
				}
			}
		}

		.page-content {
			margin-bottom: 40px;
			margin-top: 10px; 
			.user-list {
				overflow-x: scroll;
				.user-list-item {
					&.list-header {
						background-color: ${decor};
						color: ${mediumEmphasis}
						.MuiGrid-container {
							.MuiGrid-item {
								padding-left: 20px;
								&.first-item {
									padding-left: 75px;
									img {
										border: 1px solid ${decor};
										margin-right: 15px;
									}
								}
							}
						}
					}
				}
				.user-list-item {
					height: 56px;		
					font-family: ${futuraLight};
					font-style: normal;
					font-weight: 300;
					font-size: 18px;
					line-height: 28px;
					color: ${highEmphasis};	
					background-color: ${white};
					position: relative;
					min-width: 1000px;
					&.dark-bg {
						background-color: ${lightBackgorund};
					}
					.MuiGrid-container {
						align-items: center;
						height: 100%;
						.MuiGrid-item {
							&.first-item {
								padding-left: 75px;
							}
							&.last-item {
								display: flex;
								align-items: center;
								overflow-y: hidden;
								padding-right: 30px;
							}
							.img-div-user {
								max-height: 45px;
								width: 45px;
								margin-right: 15px;
								img {
									border: 1px solid ${decor};
									width: 100%;
									vertical-align: middle;
									background: #C4C4C4;
								}
							}
							.img-div-details {
								overflow-x: hidden;
								box-sizing: border-box;
								text-overflow: ellipsis;
								white-space: nowrap;
								width: 75%;
							}
							overflow-x: hidden;
							padding: 0 4px;
							box-sizing: border-box;
							text-overflow: ellipsis;
						}
					}
					.MuiCheckbox-root {
						position: absolute;
						top: 7px;
						left: 7px;
						&:hover {
							background-color: ${dividerColor}
						}
						.MuiSvgIcon-root {
							color: ${mediumEmphasis}
						}
						&.Mui-checked {
							color: ${mediumEmphasis}
						}
					}
					.open-edit {
						position: absolute;
						right: 10px;
						top: 16px;
						color: ${mediumEmphasis};
						&:hover {
							cursor: pointer;
						} 
					} 
					.edit-btn-tooltip {
						display: none;
						&.show {
							display: flex;
							position: absolute;
							z-index: 3;
							background-color: ${customBoxShadowColor};
							right: 40px;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							top: -27px;
							width: 100px;
							border: 1px solid ${dividerColor};
							box-shadow: 0 2px 2px 0 ${CustomEditShadowLight}, 0 3px 1px -2px ${CustomEditShadowMedium}, 0 1px 5px 0 ${CustomEditShadowDark};
							a {
								font-family: ${futura};
								font-size: 20px;
								width: 100%;
								text-align: center;
								height: 100%;
								line-height: 55px;
								letter-spacing: 0.1em;
								color: ${highEmphasis};
								text-decoration: none;
								background-color: ${lightBackgorund};
								&:hover {
									background-color: ${decor};
								}
							}
							button {
								font-family: ${futura};
								font-size: 20px;
								width: 100%;
								text-align: center;
								height: 100%;
								line-height: 55px;
								letter-spacing: 0.1em;
								color: ${highEmphasis};
								text-decoration: none;
								background-color: ${lightBackgorund};
								padding: 0;
								border-radius: 0;
								text-transform: capitalize;
								&:hover {
									background-color: ${decor};
								}
							}
						}
						.arrow-down {
							position: absolute;
							right: -12%;
							z-index: 4;
							bottom: 43%;
							border-top: 9px solid transparent;
							border-bottom: 9px solid transparent;
							border-left: 12px solid ${dividerColor};
						}
					}
				}
				.list-body {
					border: 1px solid ${decor};
					min-width: 1000px;
					margin-bottom: 20px;
					span {
						&.usernotfound {
							padding: 10px 16px;
							display: inline-block;
						}
					}
				}
			}			
		}
		@media only screen and (max-width: 1200px) { 
			.page-header {
				.header-btns {
					width: 65%
				}
			}
		}
		@media only screen and (max-width: 991px) { 
			.page-header {
				position: relative;
				.select-list {
					position: absolute;
					margin-top: 0;
					bottom: 0;
				}
				.header-btns {
					width: 100%;
				}
			}
		}
		@media only screen and (max-width: 767px) { 
			.page-header {
				display: flex;	
				justify-content: center;
				align-items: center;
				height: auto;
				flex-direction: column;
				margin-bottom: 35px;
				.select-list {
					bottom: -37px;
					left: 0;
					font-size: 16px;
				}
				.header-btns {
					width: 100%;
					flex-wrap: wrap;
					justify-content: center;
					.delete-user-btn {
						max-width: 120px;
						margin-bottom: 5px;
						margin-top: 20px;
						font-size: 16px;
						line-height: 41px;
					}
					.add-user-btn {
						max-width: 220px;
						margin-bottom: 5px;
						margin-top: 10px;
						a {
							font-size: 16px;
							line-height: 40px;
						}
					}
				}
			}
		}
	`,
);
