import firebaseapp from '../../utils/firebase';
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';


const db = getFirestore(firebaseapp.app);


export async function addFAQ(name, data) {
	return await addDoc(collection(db, name), data);
}

export async function getFAQDetail(name) {
	const querySnapshot = await getDocs(collection(db, name));
	const FAQDetails = [];
	querySnapshot.forEach(el => {
		FAQDetails.push({ id: el.id, ...el.data() });
	});
	return FAQDetails;
}

export async function updateFaq(name, data) {
	const docRef = doc(db, name, data.id);
	delete data.id;
	return await setDoc(docRef, data);
}

export async function deleteFaq(name, id) {
	const deleteRef = doc(db, name, id);
	return await deleteDoc(deleteRef);
}