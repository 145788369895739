import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import location from '../../assets/images/Icons/room.png';
import viewCarousel from '../../assets/images/Icons/view-carousel.png';
import business from '../../assets/images/Icons/business.png';
import cardLogo from '../../assets/images/card-logo.png';
import { StyledCard } from '../../styles/components/lisitingCard';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ListingCard = ({ station }) => {

	const groupState = useSelector(state => state.groups);

	const navigate = useNavigate();
	const formatDates = (date) => {
		if (date && date.seconds) {
			const dates = new Date(date.seconds * 1000);
			return moment(dates).format('MMMM yyyy');
		}
		return moment(date).format('MMMM yyyy');
	};

	const ViewStationDetail = (e) => {
		e.preventDefault();
		navigate(`/editSation/${station.id}`);
	};

	const groupDetail = (station) => {
		let name = '';
		groupState.GroupsList.forEach(el => {
			if (el.id === station){
				name = el.groupName;
				return el.groupName;
			}
		});
		return name;
	};

	return (
		<StyledCard className='card-dark' onClick={ViewStationDetail}>
			<div className="card-main">
				<div className="card-logo">
					{station && station.stationImage ? <img src={station.stationImage} alt="" /> : <img src={cardLogo} alt="" />}
				</div>
				<h3 className="card-title">{station && station.stationName && station.stationName}</h3>
				<p className="card-des">{station && station.stationDescription && station.stationDescription}</p>
				<div className="card-info">
					<div className="card-info-item location-item">
						{station && station.stationLocation ?
							<>
								<img src={location} alt="" /> <p title={station.stationLocation}>{station.stationLocation}</p>
							</>
							: ''
						}
					</div>
					{station && groupDetail(station.groupId) && <div className="card-info-item">
						<img src={viewCarousel} alt="" /> <p title={groupDetail(station.groupId)}>{groupDetail(station.groupId)}</p>
					</div>}
					<div className="card-info-item">
						<img src={business} alt="" /> <p>{station && station.contractExpiredate && formatDates(station.contractExpiredate)}</p>
					</div>
				</div>
				<Button className="card-button"><Link to='/'>View Station</Link></Button>
			</div>

		</StyledCard>
	);
};

export default ListingCard;
